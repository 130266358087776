<template>
  <div class="intro" ref="intro">
    <div class="intro-container">
      <div ref="animation" class="intro-animation"></div>
    </div>
  </div>
</template>

<script>
import LottieWeb from 'lottie-web/build/player/lottie_light.min.js'
import introJson from '@/assets/lottie/intro.json'
export default {
  data () {
    return {
    }
  },
  components: {
    LottieWeb
  },
  methods: {
  },
  watch: {
    '$root.locoScroll': function () {
      this.$root.locoScroll.stop()
    }
  },
  mounted () {
    document.documentElement.classList.add('no-overflow')
    const { animation } = this.$refs
    const anim = LottieWeb.loadAnimation({
      container: animation,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: introJson
    })
    anim.onComplete = () => {
      setTimeout(() => {
        document.documentElement.classList.remove('no-overflow')
        this.$root.introComplete()
        this.$root.locoScroll.start()
      }, 1000)
    }
  },
  unmounted () {
    LottieWeb.destroy()
  }
}
</script>

<style lang="scss">
.intro {
  @apply fixed top-0 left-0 w-full h-full z-50 bg-gradient-to-tr from-pageLoadingfrom to-pageLoadingTo backdrop-blur-xl;
  .intro-container {
    @apply absolute top-0 left-0 flex items-center justify-center w-[100vw] h-[100vh] flex items-center justify-center bg-cover bg-no-repeat relative top-[-50px];
    .intro-animation {
      @apply w-[60%] h-[60%] md:w-[500px] md:h-[500px] m-auto;
    }
  }
}
</style>
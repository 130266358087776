<template>
  <div class="search-container">
    <a id="clouseImg" v-on:click="clouseSearch">
      <img :src="searchClouse" alt />
    </a>
    <div class="search">
      <div class="search__input">
        <input
          v-on:keyup="getResult"
          placeholder="Search"
          v-model="searchValue"
          type="text"
        />
        <img :src="searchIcon" alt="Search icon" />
      </div>
      <div class="search__results">
        <a
          v-for="searchItem in searchResult"
          :key="searchItem.id"
          v-on:click="clouseSearch"
          :href="searchItem.url"
          class="search__results__item"
        >
          <div class="left">
            <h3 v-html="searchItem.title "></h3>
            <div v-html="searchItem.content.substring(0, 250)"></div>
          </div>
          <div class="right">
            <span>{{ searchItem.subtype }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import searchIcon from '@/assets/img/search.svg'
import searchClouse from '@/assets/img/clouse.svg'
import { debounce } from 'debounce'

export default {
  props: ['display'],
  data () {
    return {
      searchIcon,
      searchClouse,
      searchResult: [],
      serachValue: ''
    }
  },
  methods: {
    clouseSearch () {
      this.$emit('displaySearch')
    },
    getResult () {
      const getSearch = debounce(this.getResultFromApi, 1000)
      return getSearch()
    },
    getResultFromApi () {
      this.$store
        .dispatch('getItems', {
          type: 'search',
          params: {
            per_page: 20,
            page: 1,
            search: this.searchValue
          }
        })
        .then(() => {
          const getPosts = this.$store.getters.requestedItems({
            type: 'search',
            params: {
              per_page: 20,
              page: 1,
              search: this.searchValue
            }
          })
          this.searchResult = []
          this.searchResult = getPosts
        })
    }

  },
  mounted () {
    gsap.fromTo(
      '.search-container',
      { autoAlpha: 0 },
      {
        autoAlpha: 0.96
      }
    )
  },
  created () {
    this.$root.locoScroll.stop()
  },
  unmounted () {
    this.$root.locoScroll.start()
  }

}
</script>

<style lang="scss" scoped>

.search-container {
  @apply fixed inset-0 z-2 w-100vw h-100vh bg-primary/95 px-[10vw] py-[10vh];

  .search {
    @apply mt-5;

    &__input {
      @apply border-b-2 border-white flex flex-row justify-between h-18;
      input {
        @apply w-full bg-transparent border-none text-white text-xl lg:text-4xl text-center focus:outline-none focus:ring-0 placeholder-white placeholder-opacity-50;
      }
      img {
        @apply w-8 my-4;
      }
    }
    &__results {
      @apply h-[65vh] overflow-auto;
      &__item {
        @apply w-full flex flex-row justify-between items-center border-b-1 p-2 my-6 hover:bg-[#ffffff1a];
        .left {
          h3 {
            @apply text-xl text-white text-left;
          }
          div {
            @apply text-base text-white text-left w-1/2  line-clamp-2;
          }
        }
        .right {
          @apply h-full bg-white text-primary py-2 px-4 rounded-full;
        }
      }
    }
  }
  #clouseImg {
    @apply absolute top-[5%] right-[5%] cursor-pointer;
    img {
      @apply w-10;
    }
  }
}
</style>

<template>
  <div
    class="spacer"
    :style="`${height > 0 ? `padding-top: ${height}px;` : `margin-top: ${height}px;`} background-color: ${data.background_color}; box-shadow: 0 0 0 1px ${data.background_color};`"
  />
</template>

<script>
export default {
  data: function () {
    return {
      deviceWidth: 0,
      height: 0,
    }
  },
  props: [
    'data',
  ],
  watch: {
    deviceWidth(newVal) {
      if (newVal > 1024) {
        this.height = this.data.spacer_height;
      } else if (newVal > 768) {
        this.height = this.data.spacer_height_tablet;
      } else {
        this.height = this.data.spacer_height_mobile;
      }
    }
  },
  created() {
    this.deviceWidth = window.innerWidth;
  },
  mounted() {
    // console.log(this.data)
    window.addEventListener('resize', () => {
      this.deviceWidth = window.innerWidth;
    });
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.deviceWidth = window.innerWidth;
    });
  }
}
</script>

<style lang="scss" scoped>
.spacer {
}
</style>
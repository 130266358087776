<template>
  <section class="news main-container">
    <div class="inner-container">
      <div class="title">
        <p class="gsap_news">{{ data.title_area.sub_title }}</p>
        <h2 class="gsap_news">{{ data.title_area.title }}</h2>
        <div class="gsap_news" v-html="data.content_area.description"></div>
        <Button
          class="gsap_news"
          :text="data.title_area.button.title"
          :url="data.title_area.button.url"
          :target="data.title_area.button.target"
          color="white"
        ></Button>
      </div>
      <div v-if="allPost.length > 0" class="post">
        <article
          class="post__item"
          v-for="(post, index) in allPost"
          :key="index"
        >
          <div class="post__item__img">
            <img :src="post.featured_image ? post.featured_image : defaultImage" :alt="post.post_title" />
          </div>
          <div class="post__item__text">
            <span v-for="cat in post.categories" :key="cat.id">{{ cat }}</span>
            <h3 v-html="post.post_title "></h3>

           <!--  <div
              v-html="
                post.post_excerpt !== '' ? post.post_excerpt : post.post_content
              "
            ></div> -->
            <Button
              class
              :text="'Read More'"
              :url="post.post_url"
              target="_self"
              color="white"
            ></Button>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Button from '@/components/utility/Button.vue'
import defaultImage from '@/assets/img/default-cover-news.jpg'

export default {
  data () {
    return {
      allPost: [],
      defaultImage
    }
  },
  components: {
    Button
  },
  props: ['data'],
  methods: {
    animations () {
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(
        '.news .post__item',
        { x: -100, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: '.news .post',
            scroller: '[data-scroll-container]',
            start: this.$root.isMobile ? 'top center' : '-60% center',
            end: 'center center',
            markers: false
          },
          stagger: 0.1,
          x: 0,
          autoAlpha: 1
        }
      )
    },

    getNews (number) {
      const arrPostID = []
      for (const single of this.data.content_area.news) {
        arrPostID.push(single.ID)
      }

      let params = {}
      if (this.data.content_area.exclude_category) {
        params = {
          per_page: number,
          exclude: arrPostID.toString(),
          categories_exclude: this.data.content_area.exclude_category
          // _fields: "id,title,link,featured_image, excerpt, categories, categories_info",
        }
      } else {
        params = {
          per_page: number,
          exclude: arrPostID.toString()
          // _fields: "id,title,link,featured_image, excerpt, categories, categories_info",
        }
      }

      this.$store
        .dispatch('getItems', {
          type: 'posts',
          params
        })
        .then(() => {
          const getPosts = this.$store.getters.requestedItems({
            type: 'posts',
            params
          })
          for (const single of this.data.content_area.news) {
            const arrTemp = {
              id: single.ID,
              post_title: single.post_title,
              post_content: single.post_content,
              post_excerpt: single.post_excerpt,
              featured_image: single.featured_image,
              categories: single.categories,
              guid: single.guid,
              post_url: single.post_url
            }
            this.allPost.push(arrTemp)
          }
          for (const single of getPosts) {
            const arrCat = []
            for (const cat of single.categories_info) {
              arrCat.push(cat.name)
            }
            const arrTemp = {
              id: single.id,
              post_title: single.title.rendered,
              post_content: single.content.rendered,
              post_excerpt: single.excerpt.rendered,
              featured_image: single.featured_image.large,
              categories: arrCat,
              guid: single.guid.rendered,
              post_url: single.link
            }
            this.allPost.push(arrTemp)
          }
        })
    }
  },
  created () {
    if (
      this.data.content_area.news.length >= 0 &&
      this.data.content_area.news.length < 3
    ) {
      this.getNews(3 - this.data.content_area.news.length)
    } else {
      this.allPost = this.data.content_area.news
    }
  },
  updated () {
    if (this.allPost.length === 3) {
      this.animations()
    }
    // console.log(this.allPost)
  },
  mounted () {
    if (this.allPost.length === 3) {
      this.animations()
    }
  }
}
</script>
<style lang="scss" scoped>
.main-container {
  @apply bg-text h-[100vh] h-full;
  .inner-container {
    @apply w-full max-w-screen-xl mx-auto py-16 px-4 gap-4 grid grid-cols-1 lg:grid-cols-4;
    .title {
      @apply w-full col-span-1;
      h2 {
        @apply text-3xl text-white;
      }
      div {
        @apply text-white mt-6 mb-0;
      }
      p {
        @apply text-white;
      }
    }
    .post {
      @apply col-span-3 w-full px-0 gap-4 md:px-3
      lg:p-0 flex flex-row justify-start justify-items-center
      lg:justify-center sm:justify-between overflow-x-auto lg:overflow-hidden snap snap-x snap-mandatory;
      &__item:first-child {
        @apply ml-0 lg:ml-3;
      }
      &__item {
        @apply flex-1 bg-primary mb-4 snap-center w-full lg:w-4/12;
        &__img {
          @apply w-full h-60 overflow-hidden;
          img {
            @apply h-full w-full object-cover;
          }
        }
        &__text {
          @apply text-white p-4;
          span {
            @apply text-sm mr-2;
          }
          h3 {
            @apply text-[22px] mb-4 line-clamp-6 leading-6 h-[140px];
          }
          div:deep() {
            @apply line-clamp-3 min-h-[4.5rem] max-h-[4.5rem] overflow-hidden;
            * {
              @apply my-0 text-base font-normal leading-6;
            }
            *:not(:first-child) {
              @apply hidden;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="footer-nav-item-container">
    <nav>
      <ul class="nav-container">
        <template v-for="item in menu">

          <li v-if="item.parent === '0'" :key="item.id" :title="item.title" :class="item.class">

            <a
              :href="item.url"
              v-html="item.content"
              :target="item.target"
            ></a>
            <ul class="sub-menu">
              <template v-for="sub in menu">
                <li
                  v-if="sub.parent == item.id"
                  :key="sub.id"
                  :title="sub.title"
                  :class="item.class"
                >
                  <router-link
                    :to="sub.url"
                    v-html="sub.content"
                    :target="item.target"
                  ></router-link>
                </li>
              </template>
            </ul>
            <div
              class="footer-contact"
              v-if="item.class.includes('contact')"
              v-html="acf_options.footer_contact"
            ></div>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'footer-menu',
  data () {
    return {
      menu: this.$store.getters.menu({ name: this.name })
    }
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    acf_options () {
      return this.$store.getters.acfOptions()
    }
  },
  beforeMount () {

  }
}
</script>

<style lang="scss" scoped>
.nav-container {
  @apply text-center lg:flex lg:divide-x lg:divide-white-500;

  li:not(:first-child) {
    @apply lg:pl-4;
  }
  li {
    @apply lg:pr-4 py-2 lg:py-0;
    &:hover {
      color: white;
    }
  }
}
</style>

<template>

  <header
    ref="header"
    data-scroll
    data-scroll-sticky
    data-scroll-target=".target"
    class="no-cursor header-nav white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      $root.isTablet || $root.isMobile ? 'mobile' : ''
    }
    `"
    id="header-nav"
  >
    <nav ref="navWrapper">
      <div class="branding">
        <div class="logo-container">
          <a href="/">
            <Transition v-if="$root.isDesktop" name="fade" mode="out-in">
              <img
                v-if="site.site_logo.url && !sticky"
                :src="site.site_logo.url"
              />
              <img v-else :src="site.site_logo_sticky.url" />
            </Transition>
            <Transition v-else name="fade" mode="out-in">
              <img :src="site.site_logo_sticky.url" />
            </Transition>
          </a>
        </div>
        <div class="mobile-nav" @click="toggle">
          <span class="line line-one" />
          <span class="line line-two" />
          <span class="line line-three" />
        </div>
      </div>
      <ul class="nav-container" ref="nav">
        <template v-if="this.$store.state.site.introShown">
          <transition-group
            tag="li"
            v-for="(item, index) in topLevelMenu(menu)"
            appear
            @before-enter="beforeEnter"
            @enter="enter"
            :key="item.id"
            @click="item.class[0] === '' ? close(false) : null"
          >
            <template v-if="item.parent === '0'">
              <template v-if="item.class[0] != 'search_link'">
                <NavMenuItem
                  :data-ind="index"
                  :key="item.id"
                  :menu="menu"
                  :item="item"
                  @clicked="close"
                  :mobile="$root.isMobile ? 'mobile-menu-show' : ''"
                />
              </template>
              <template v-else-if="item.class[0] === 'orange'">
                <NavMenuItem
                  :data-ind="index"
                  :key="item.id"
                  :menu="menu"
                  :item="item"
                  @clicked="close"
                  :mobile="$root.isMobile ? 'mobile-menu-show' : ''"
                />
              </template>
              <template v-else>
                <img
                  v-on:click="showSearch"
                  id="searchIcon"
                  :key="item.id"
                  :src="searchIcon"
                  alt="Search icon"
                />

                <Search
                  v-on:displaySearch="showSearch"
                  v-if="displaySearch == true"
                ></Search>
              </template>
            </template>
          </transition-group>
        </template>
      </ul>
    </nav>
  </header>
</template>

<script>
import NavMenuItem from './NavMenuItem.vue'
import Search from './Search.vue'
import gsap from 'gsap'
import searchIcon from '@/assets/img/search.svg'

export default {
  name: 'NavMenu',
  data () {
    return {
      menu: this.$store.getters.menu({ name: 'main-menu' }),
      site: this.$store.getters.acfOptions(),
      navOpen: false,
      sticky: false,
      searchIcon,
      displaySearch: false
    }
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  components: {
    NavMenuItem,
    Search
  },
  watch: {
    '$root.scroll': function () {
      this.setBlackHeader()
      this.stickyOnScroll()
    }
  },
  methods: {
    showSearch () {
      this.displaySearch = !this.displaySearch

      const html = document.querySelector('html')
      if (this.displaySearch) {
        html.classList.add('no-overflow')
      } else {
        html.classList.remove('no-overflow')
      }
    },
    topLevelMenu: (menue) => {
      const topMenu = menue.filter((x) => x.parent === '0')
      return topMenu
    },
    toggle () {
      this.navOpen = !this.navOpen
    },
    close (data) {
      if (data !== undefined) this.navOpen = data
      else this.navOpen = !this.navOpen
    },
    beforeEnter (el) {
      // add the initial style to the elements
      el.style.transform = 'translateY(10px)'
      el.style.opacity = 0
    },
    enter (el) {
      gsap.to(el, {
        y: 0,
        opacity: 1,
        duration: 0.3,
        delay: el.dataset.ind * 0.3,
        onComplete: () => {
          this.setBlackHeader()
        }
      })
    },
    setBlackHeader () {
      const headerNav = document.querySelector('#header-nav')
      if (headerNav) {
        if (
          this.$root.scroll > 0 &&
          !this.$root.isTablet &&
          !this.$root.isMobile
        ) {
          headerNav.classList.add('black')
          headerNav.classList.remove('white')
        } else {
          headerNav.classList.add('white')
          headerNav.classList.remove('black')
        }
        if (!this.$root.isMobile) {
          headerNav.classList.remove('white')
        } else if (this.$root.isMobile) {
          headerNav.classList.add('white')
          headerNav.classList.remove('black')
        }
      }
    },
    stickyOnScroll () {
      if (
        this.$root.scroll > 0 &&
        !this.$root.isTablet &&
        !this.$root.isMobile
      ) {
        if (this.$refs.header) {
          this.$refs.header.classList.add('sticky')
          this.sticky = true
          this.$refs.header.classList.add('black')
          this.$refs.header.classList.remove('white')
        }
      } else {
        if (this.$refs.header) {
          this.$refs.header.classList.remove('sticky')
          this.sticky = false
          if (this.$root.isMobile || this.$root.isTablet) {
            this.$refs.header.classList.remove('black')
            this.$refs.header.classList.add('white')
          }
        }
      }
    }
  },
  computed: {

  },
  mounted () {
    this.setBlackHeader()
    this.stickyOnScroll()
  }
}
</script>

<style lang="scss" scoped>
#header-nav {
  &.mobile {
    @apply !fixed top-0 bg-[rgb(0,64,35)] lg:bg-menuBar;
  }
}

#header-nav {
  .logo-container img {
    @apply filter transition-all duration-300;
  }
}
#header-nav.white {
  @apply mb-[50px];
  nav {
    @apply border-b-[#e9edee];
  }
  nav > ul > li > li {
    @apply transition-all duration-100 delay-0 text-black;
  }
  .desktop-subnav-parent .chevron {
    @apply stroke-white;
  }
  .logo-container img {
    @apply filter brightness-100;
  }
}

#header-nav.black {
  @apply absolute mb-[50px];
  nav {
    @apply border-b-[#a6a6a6];
  }
  nav > ul > li > li {
    @apply transition-all duration-500 delay-500 text-black;
  }
  .desktop-subnav-parent .chevron {
    @apply stroke-black;
  }
}
#header-nav.sticky {
  @apply top-0 mt-0 lg:fixed shadow-xl transition transition-all duration-100;
  @apply lg:bg-menuBar transition-colors duration-100;
  nav {
    @apply max-h-[70px] border-none;
    .logo-container {
      img {
        @apply w-[80%] lg:w-auto h-full !max-h-[50px] lg:!mt-0;
      }
    }
  }
}
#header-nav.nav-open {
  @apply max-h-[100vh] lg:max-h-[110px] transition-all duration-[2s] ease-in-out;
  .line-one {
    @apply transform rotate-45 translate-y-4 translate-x-[0px];
  }
  .line-three {
    @apply transform -rotate-45 -translate-y-[-4px] -translate-x-[0px];
  }
  .line-two {
    @apply opacity-0;
  }
}
.header-nav {
  @apply flex mt-0 mb-0 items-stretch
         justify-center w-[100vw] top-0 min-h-[70px]
       bg-menuBar overflow-hidden lg:overflow-visible
         transition-all duration-[1s] ease-in-out max-h-[80px] lg:max-h-[110px]
         bg-menuBar lg:bg-menuBar absolute z-10;
  nav {
    @apply flex flex-col lg:flex-row items-stretch lg:h-[110px] lg:max-h-[110px]
    lg:items-center w-full max-w-screen-xl pt-0 xl:px-3 2xl:px-0 lg:py-0 2xl:px-0
     lg:border-b-solid transition-all duration-300 ease-in-out;
    ul {
      @apply flex flex-col my-3 lg:my-0 lg:flex-row justify-start lg:gap-6 items-center lg:items-end md:px-0 lg:px-5 xl:px-0;
      li:nth-child(1) {
        @apply inline-block ml-0 xl:ml-5 lg:w-auto;
      }
      li {
        @apply w-full lg:w-auto text-center;
      }
    }
  }
  .branding {
    @apply w-full lg:w-[30%] xl:w-[35%] text-center
           md:text-left flex items-center lg:max-h-[65px]
           justify-between p-3 xl:p-0 shadow-lg lg:shadow-none;
    .logo-container {
      @apply h-full xl:pl-2 xl:max-h-full flex items-center lg:inline-block;
      img {
        @apply w-[80%] lg:w-auto h-full max-h-[60px] lg:max-h-[80px];
      }
    }
    .mobile-nav {
      @apply flex flex-col lg:hidden float-right p-3;
      .line {
        @apply mb-2 transition-all duration-[0.5s] ease-in-out;
      }
      .line:before {
        @apply content-[''] block h-[2px] w-[30px] bg-white;
      }
    }
  }

  .nav-container {
    @apply w-full
           lg:w-[70%] xl:w-[75%] text-center md:text-left
           transition duration-500 ease-in-out m-0 place-items-start justify-end;
    height: fit-content;
  }
}
#searchIcon {
  @apply mt-[5px] lg:pl-5 cursor-pointer mx-auto py-4 lg:py-0;
}
</style>

<template>
  <section class="quote-section" :class="[`quote-section-${uuid}`, {'dark-mode': quote_section.isDarkMode}]">
    <div class="quote-section-content" :class="`quote-section-content-${uuid}`">
      <div class="quote-section-text" :class="quote_section.isDarkMode ?  'text-white' : ''"  v-html="quote_section.content"></div>
    </div>
    <div class="quote-section-author text-right" :class="`quote-section-author-${uuid}`">
      <p class="quote-section-text ">{{ quote_section.author }}</p>
    </div>
  </section>
</template>
<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
  props: ['data', 'quote', 'fromProps'],
  data () {
    return {
      uuid: ''
    }
  },
  computed: {
    quote_section () {
      return this.fromProps
        ? this.quote
        : {
            content: this.data.quote_section_content,
            author: this.data.quote_section_author,
            isDarkMode: this.data.quote_section_is_dark_mode
          }
    }
  },
  methods: {
    generateUuid () {
      this.uuid = `${Math.floor(Math.random() * (564 * 100))}`
    },
    animateQuote () {
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(
        `.quote-section-content-${this.uuid}`,
        { x: -10, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: `.quote-section-${this.uuid}`,
            scroller: '[data-scroll-container]',
            start: '-200 center',
            end: 'bottom center'
          },
          x: 0,
          delay: 0.5,
          duration: 1,
          autoAlpha: 1
        }
      )
      gsap.fromTo(
        `.quote-section-author-${this.uuid}`,
        { y: -10, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: `.quote-section-${this.uuid}`,
            scroller: '[data-scroll-container]',
            start: '-200px center',
            end: 'bottom center'
          },
          y: 0,
          delay: 1,
          duration: 1,
          autoAlpha: 1
        }
      )
    }
  },
  created () {
    this.generateUuid()
  },
  mounted () {
    this.animateQuote()
  },
  updated () {
    ScrollTrigger.refresh()
  }
}
</script>
<style lang="scss" scoped>
.quote-section {
  @apply bg-white flex flex-col w-full max-w-screen-xl mx-auto py-16 md:py-18 lg:py-24 px-4 md:px-10 lg:px-18 xl:px-20 space-y-8 md:space-y-10 lg:space-y-12 my-2;

  p {
    @apply text-text font-neueHaasUnica font-normal not-italic;
  }

  .quote-section-content {
    p {
      @apply leading-[45px] md:leading-[60px] tracking-[-0.04em] text-5xl md:text-[60px];
    }

    .quote-section-text:deep(){
      &::before {
        content: '“';
      }
      p {
        @apply  leading-[45px] md:leading-[60px] tracking-[-0.04em] text-5xl md:text-[60px];
      }
      &::after {
        content: '”';
      }
    }
  }
  .quote-section-author {
    p {
      @apply leading-6 text-[18px]  uppercase mr-12;
    }
  }

  &.dark-mode {
    @apply bg-text;

    .quote-section-text {
      @apply text-white leading-[45px] md:leading-[60px] tracking-[-0.04em] text-5xl md:text-[60px];

    }
    .quote-section-author:deep(p) {
      @apply text-white not-italic leading-6 text-[18px] uppercase mr-12;
    }
  }
}
</style>

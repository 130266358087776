<template>
  <template v-if="(index + 1) % 7 === 0 && download == false">

    <div class="project-card" :class="width"
      :style="`${image ? 'background-image:url(' + image + ');' : 'background-image:url(' + defaultCover + ');'}`">
      <div class="video-content" v-if="video">
        <video style="width:100%; height:100%" autoplay muted="muted" :poster="image" loop playsinline>
          <source :src="video.url" type="video/mp4">
        </video>
      </div>
      <div class="inner-project-card-container">
        <!-- <div class="award-image" v-if="isAwards">
          <img :src="awardImage ? awardImage.url : defaultAward" alt="Award Image" />
        </div> -->
        <p class="category text-[12px] mb-3 md:mb-7 text-white" v-html="projectCategory" />
        <h4 class="title text-white" v-html="title" />
        <!--    <p class="line-clamp-4 lg:line-clamp-2 text-white max-h-[100px]" v-html="description" /> -->
        <!--     <div class="award-data" v-if="isAwards">
          <p class="line-clamp-4 lg:line-clamp-2 text-white max-h-[100px]">- {{awardSubtitle}}</p>
          <p class="line-clamp-4 lg:line-clamp-2 text-white max-h-[100px]">- {{awardName}}</p>
        </div> -->
        <template v-if="image || projectCategory != 'Awards'">
          <Button :url="link" target="_self" color="white" :text="'Read More'"></Button>
        </template>
      </div>
    </div>

    <div class="project-card cta bg-primary" :class="width" v-if="shotCTA">
      <div class="inner-project-card-container">

        <p class="category text-[12px] mb-3 md:mb-7 text-white">{{ ctaContent.top_text }}</p>
        <h4 class="title text-accentGreen">{{ ctaContent.title }}</h4>
        <p class="line-clamp-4 lg:line-clamp-4 text-white max-h-[100px]">{{ ctaContent.content }}</p>
        <Button :url="ctaContent.button.url" :target="ctaContent.button.target" color="white"
          :text="ctaContent.button.title"></Button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="project-card" :class="width"
      :style="`${image ? 'background-image:url(' + image + ');' : 'background-image:url(' + defaultCover + ');'}`">
      <div class="video-content" v-if="video">
        <video style="width:100%; height:100%" autoplay muted="muted" :poster="image" loop playsinline>
          <source :src="video.url" type="video/mp4">
        </video>
      </div>
      <div class="inner-project-card-container">
        <!-- <div class="award-image" v-if="isAwards">
          <img :src="awardImage ? awardImage.url : defaultAward" alt="Award Image" />
        </div> -->
        <p class="category text-[12px] mb-3 md:mb-7 text-white" v-html="projectCategory" />
        <h4 class="title text-white" v-html="title" />
        <!--  <p class="line-clamp-4 lg:line-clamp-2 text-white max-h-[100px]" v-html="description" /> -->
        <!--       <div class="award-data" v-if="isAwards">
          <p class="line-clamp-4 lg:line-clamp-2 text-white max-h-[100px]">- {{awardSubtitle}}</p>
          <p class="line-clamp-4 lg:line-clamp-2 text-white max-h-[100px]">- {{awardName}}</p>
        </div> -->
        <template v-if="image || projectCategory != 'Awards'">
          <Button :url="link" :target="download ? '_blank' : '_self'" color="white"
            :isdownload="download ? true : false" :fileName="title"
            :text="buttonText ? buttonText : 'Read More'"></Button>
        </template>
      </div>
    </div>
  </template>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue'
import Button from '../utility/Button.vue'
import defaultCover from '@/assets/img/default-cover.jpg'

const projectCategory = ref('')
// import defaultAward from '@/assets/img/default-trophy.png'

const props = defineProps([
  'idProject',
  'category',
  'title',
  'image',
  'video',
  'description',
  'link',
  'width',
  'index',
  'isProject',
  'isAwards',
  'awardSubtitle',
  'awardName',
  'awardImage',
  'shotCTA',
  'ctaContent',
  'subTitle',
  'buttonText',
  'download',
  'primaryCategory'

])

const projectCat = () => {
  if (!props.subTitle) {
    projectCategory.value = props.category
  } else {
    projectCategory.value = props.subTitle
  }
}
onBeforeMount(() => {
  projectCat()
})

</script>
<style lang="scss" scoped>
.project-card {
  @apply flex-grow relative;

  .video-content {
    @apply absolute top-0 left-0 right-0 bottom-0;

    video {
      @apply object-cover;
      @apply object-center;
      @apply w-full;
      @apply h-full;
    }
  }

  &:before {
    @apply absolute w-full h-full bg-gradient-to-b to-text from-primary/0 opacity-60 left-0 top-0 content-[''] z-1;
  }

  &.full {
    @apply w-full;
  }

  &.half {
    @apply w-[49%];
  }

  @apply w-full h-[510px] flex items-end p-5 md:p-10 bg-no-repeat bg-cover bg-center;

  .inner-project-card-container {
    @apply w-full lg: w-3/4 z-2;

    .award-image {
      @apply h-40 w-40 absolute top-4 right-4 bg-white shadow-lg;

      img {
        @apply object-contain object-center w-full h-full p-2;
      }
    }

    .award-data {
      @apply min-h-20;
    }

    .title {
      @apply mb-4 text-[30px] md: text-[32px];
    }
  }
}
</style>

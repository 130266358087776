<template>
  <section class="imageCta main-container" :style="`background-color: ${data.content.background};`">
    <img :src="data.content.image.url" alt />
    <div class="inner-container">
      <div class="cta" :style="`background-color: ${data.content.background};`">
        <p class="gsap_imageCTA">{{ data.title.mini_headline }}</p>
        <h2 class="gsap_imageCTA">{{ data.title.headline }}</h2>
        <div class="gsap_imageCTA" v-html="data.title.content"></div>
        <Button
          class="gsap_imageCTA"
          :text="data.content.button.title"
          :url="data.content.button.url"
          :target="data.content.button.target"
          color="white"
        ></Button>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Button from '@/components/utility/Button.vue'

export default {
  data () {
    return {}
  },
  components: {
    Button
  },
  props: ['data'],
  mounted () {
    // console.log(this.data, 'imageCTA');
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.gsap_imageCTA',
      { x: 100, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: '.imageCta',
          start: 'top center',
          scroller: '[data-scroll-container]',
          end: 'center center'
        },
        x: 0,
        duration: 0.3,
        autoAlpha: 1

      }
    )
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  @apply h-[100vh] h-full relative overflow-x-hidden;
  img {
    @apply lg:absolute h-80vw lg:h-full object-cover w-auto xl:w-60vw;
  }
  .inner-container {
    @apply w-full max-w-screen-xl mx-auto flex justify-end min-h-[57vh];
    .cta {
      @apply w-full md:h-auto md:w-[50%] lg:w-[35%] p-10 md:p-16 z-1 opacity-90 md:opacity-100 flex flex-col justify-center;
      h2 {
        @apply text-3xl text-white;
      }
      div {
        @apply text-white mt-6 mb-0;
      }
      p {
        @apply text-white;
      }
    }
  }
}
</style>

<template>
  <atropos :active-offset="2" :shadow-scale="0" :rotateXMax="5" :rotateYMax="5"  :class="`${uid}_card card`" v-if="!isFirefox()">
    <div class="box">
        <span class="box__subTitle gsap_text">{{
            subtitle
        }}</span>
        <h2 class="box__title gsap_text">{{ title }}</h2>
        <div class="box__content gsap_text" v-html="content"></div>
        <Button
        v-if="button.title"
          class="gsap_text" :text="button.title"
          :url="button.url"
          :target="button.target"
          color="green"></Button>
      </div>
  </atropos>
  <div :active-offset="2" :shadow-scale="0" :rotateXMax="5" :rotateYMax="5"  :class="`${uid}_card card`" v-else>
    <div class="box">
        <span class="box__subTitle gsap_text">{{
            subtitle
        }}</span>
        <h2 class="box__title gsap_text">{{ title }}</h2>
        <div class="box__content gsap_text" v-html="content"></div>
        <Button
        v-if="button.title"
          class="gsap_text" :text="button.title"
          :url="button.url"
          :target="button.target"
          color="green"></Button>
      </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import Button from '@/components/utility/Button.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Atropos from 'atropos/vue'

const props = defineProps([
  'uid', 'title', 'subtitle', 'content', 'button'
])
const animationCard = () => {
  const cardHero = document.querySelector(`.${props.uid}_card`)
  let bounds

  function rotateToMouse (e) {
    const mouseX = e.clientX
    const mouseY = e.clientY
    const leftX = mouseX - bounds.x
    const topY = mouseY - bounds.y
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2
    }
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2)
    let cssTransform = `transform: scale3d(1, 1, 1.01) rotate3d( ${center.y / 100}, ${-center.x / 100}, 0, ${Math.log(distance) * 2}deg);`
    // cssTransform += ` -moz-transform: scale3d(1, 1, 1.01) rotate3d( ${center.y / 100}, ${-center.x / 100}, 0, ${Math.log(distance) * 2}deg);`
    cssTransform += ' transform-style: preserve-3d;'

    // cssTransform += ` -ms-transform: scale3d(1, 1, 1.01) rotate3d( ${center.y / 100}, ${-center.x / 100}, 0, ${Math.log(distance) * 2}deg);`

    cardHero.setAttribute('style', cssTransform)
  }

  cardHero.addEventListener('mouseenter', () => {
    bounds = cardHero.getBoundingClientRect()
    // get browser name
    const isFirefox = typeof InstallTrigger !== 'undefined'

    if (!isFirefox) {
      document.addEventListener('mousemove', rotateToMouse)
    }
  })

  cardHero.addEventListener('mouseleave', () => {
    document.removeEventListener('mousemove', rotateToMouse)
    cardHero.style.transform = ''
    cardHero.style.background = ''
  })
}

const isFirefox = () => {
  const fire = typeof InstallTrigger !== 'undefined'
  return fire
}
onMounted(() => {
  // animationCard()

  gsap.registerPlugin(ScrollTrigger)
  gsap.fromTo(
      `.${props.uid} .gsap_text`,
      { x: 100, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: `.${props.uid}`,
          scroller: '[data-scroll-container]',
          start: 'top-=10% center',
          end: 'bottom center',
          markers: false
        },
        x: 0,
        autoAlpha: 1,
        duration: 0.3,
        delay: props.isHero ? 5 : 0.3
      }
  )
})

</script>

<style lang="scss" scoped>
.card{
  @apply bg-white;
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
  touch-action: auto;
  &:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

  .box {

    @apply p-4 md: p-16 bg-white ;

    &__subTitle {
      @apply text-base text-green-900;
    }

    &__title {
      @apply text-5xl text-text mb-5;
    }

    &__content {
      @apply text-base text-text;
    }
  }
}

</style>

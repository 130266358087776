<template>
  <footer class="site-footer" v-if="!this.$store.state.site.loading">
    <div class="gutter-wrapper">
      <div class="footer-container">
        <div class="footer-head">
          <router-link :to="'/'" title="Home">
            <img
              v-if="acf_options.site_logo_footer"
              class="h-18"
              :src="acf_options.site_logo_footer.url"
              :alt="acf_options.site_logo_footer.alt"
            />
          </router-link>
          <div class="flex flex-col lg:flex-row gap-4 lg:gap-0">
            <div class="lg:mr-10 mt-2 self-center text-[15px]">
              <footer-menu name="footer-menu" />
            </div>
            <div class="social-media">
              <a :href="acf_options.facebook" target="_blank">
                <img
                  class="w-[30px] h-[30px] mr-5"
                  src="@/assets/img/Facebook.svg"
                />
              </a>
              <a :href="acf_options.linkedin" target="_blank">
                <img
                  class="w-[30px] h-[30px] mr-5"
                  src="@/assets/img/LinkedIn.svg"
                />
              </a>
              <a :href="acf_options.instagram" target="_blank">
                <img
                  class="w-[30px] h-[30px] mr-5"
                  src="@/assets/img/Instagram.svg"
                />
              </a>
              <a :href="acf_options.twitter" target="_blank">
                <img
                  class="w-[30px] h-[30px] mr-5"
                  src="@/assets/img/Twitter.svg"
                />
              </a>
            </div>
            <p class="lg:ml-5 self-center text-[12px]">
              Jacobsen Construction ©{{ currentYear }}
            </p>
          </div>
        </div>
        <div class="footer-body"></div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
export default {
  components: {
    FooterMenu
  },
  data () {
    return {}
  },
  computed: {
    acf_options () {
      return this.$store.getters.acfOptions()
    },
    currentYear () {
      return new Date().getFullYear()
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.site-footer {
  @apply w-full mx-auto bg-[#00643C] text-accentGreen py-10 px-6 xl:px-0;
}
.gutter-wrapper {
  @apply w-full max-w-screen-xl mx-auto;
}

.footer-head {
  @apply flex flex-col items-center py-8 lg:flex-row lg:justify-between lg:py-10 lg:pb-8;
  .social-media {
    @apply flex py-2;
    img:hover {
      @apply filter brightness-150;
    }
  }
}
</style>

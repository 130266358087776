<template>
  <section class="banner-section main-container" id="banner-top">
    <div class="inner-container">
      <div class="banner-container">
        <div
          :class="idx === 0 ? 'banner-1' : 'banner'"
          v-for="(location, idx) in data.banner_set"
          :key="idx"
        >
          <img class="banner-img" :src="location.image.url" />
          <div class="banner-data">
            <div class="banner-data-idx">
              {{ idx + 1 }}
            </div>
            <h4>{{ location.content_area.title }}</h4>
            <p class="text-[20px] mb-4">{{location.content_area.sub_title}}</p>
            <div class="banner-data-info">
              <p v-html="location.content_area.content" />
            </div>
            <Button
              class="gsap_text"
              :text="location.content_area.button.title"
              color="green"
              :url="location.content_area.button.url"
              target="_blank"
              :fileName="fileName(location.content_area.button.url)"
              :isdownload="true"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/utility/Button.vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  data () {
    return {
    }
  },
  computed: {

  },
  methods: {
    fileName (url) {
      // get file name from url

      const urlSplit = url.split('/')
      const fileName = urlSplit[urlSplit.length - 1]
      return fileName
    }
  },
  props: ['data'],
  components: {
    Button
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.banner',
      { x: -10, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: '.banner',
          scroller: '[data-scroll-container]',
          start: 'top center',
          end: 'bottom center'
        },
        x: 0,
        delay: 0,
        duration: 1,
        autoAlpha: 1,
        stagger: 1
      }
    )
  }
}
</script>

<style lang='scss' scoped>
.main-container {
  @apply h-[100vh] h-full;
  .inner-container {
    @apply w-full mx-auto max-w-screen-xl px-4 py-16 lg:h-full;
    .title {
      @apply pb-16;
    }
    .map-container {
      @apply w-full min-h-[317px] mb-[75px] lg:min-h-[670px];
      .google-map {
      }
    }
    .banner-container {
      @apply flex flex-col gap-[40px];
      .banner-1 {
        @apply border-b-2 border-secondaryGray flex flex-col lg:flex-row-reverse lg:pb-[40px];
        &-img {
          @apply object-cover w-full lg:w-[60%] xl:w-[65%] lg:min-w-[700px] lg:min-h-[375px] lg:max-h-[375px];
        }
        &-data {
          @apply px-16 py-10 relative flex flex-col gap-[14px] w-full lg:w-[40%];
          h4 {
            @apply font-medium;
          }
          &-name-business {
            @apply text-[20px];
          }
          &-info {
            p {
            }
          }
          &-idx {
            @apply bg-primaryShade text-white w-[34px] h-[34px] flex items-center justify-center
                absolute left-0;
          }
        }
        &:last-child {
          @apply border-none;
        }
      }
      .banner {
        @apply border-b-2 border-secondaryGray flex flex-col lg:flex-row-reverse lg:pb-[40px];
        &-img {
          @apply object-cover w-full lg:w-[60%] xl:w-[65%] lg:min-w-[700px] lg:min-h-[375px] lg:max-h-[375px];
        }
        &-data {
          @apply px-16 py-10 relative flex flex-col gap-[14px] w-full lg:w-[40%];
          h4 {
            @apply font-medium;
          }
          &-name-business {
            @apply text-[20px];
          }
          &-info {
            p {
            }
          }
          &-idx {
            @apply bg-primaryShade text-white w-[34px] h-[34px] flex items-center justify-center
                absolute left-0;
          }
        }
        &:last-child {
          @apply border-none;
        }
      }
    }
  }
}
</style>

<template>
    <section v-if="factsSection.facts.length > 0" :class="`facts-container-${uuid}`" class="facts-container">
      <div v-if="factsSection?.title.length > 0" :class="`facts-title-${uuid}`">
        <h3>{{factsSection.title}}</h3>
      </div>
      <div :class="`facts-wrapper-${uuid}`" class="facts-wrapper">
        <div class="fact" v-for="(fact, index) in factsSection.facts" :key="index">
          <span class="number">{{ fact.number }}{{ fact.suffix }}</span>
          <span class="label">{{ fact.bottom_content }}</span>
        </div>
      </div>
    </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
  props: ['data', 'facts', 'fromProps'],
  data () {
    return {
      uuid: ''
    }
  },
  computed: {
    factsSection () {
      return this.fromProps
        ? this.facts
        : {
            title: this.data.facts_section_title,
            facts: this.data.facts_section_facts
          }
    }
  },
  methods: {
    generateUuid () {
      this.uuid = `${Math.floor(Math.random() * (564 * 100))}`
    },
    animateTitle () {
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(
        `.facts-title-${this.uuid}`,
        { x: -10, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: `.facts-container-${this.uuid}`,
            scroller: '[data-scroll-container]',
            start: '-400 center',
            end: 'bottom center',
            markers: false
          },
          x: 0,
          delay: 0.5,
          duration: 1,
          autoAlpha: 1
        }
      )
    },
    animateFacts () {
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(
        `.facts-wrapper-${this.uuid} .fact`,
        { y: 20, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: `.facts-container-${this.uuid}`,
            scroller: '[data-scroll-container]',
            start: '-400 center',
            end: 'bottom center',
            markers: false
          },
          y: 0,
          duration: 0.5,
          autoAlpha: 1,
          stagger: 0.25,
          delay: 0.25
        }
      )
    }
  },
  created () {
    this.generateUuid()
  },
  mounted () {
    this.animateFacts()

    if (this.factsSection.title.length > 0) {
      this.animateTitle()
    }
  },
  updated () {
    ScrollTrigger.refresh()
  }
}
</script>

<style lang="scss" scoped>
.facts-container {
  @apply bg-white flex flex-col w-full max-w-screen-xl mx-auto py-8 md:py-12 lg:py-16 px-4 md:px-10 lg:px-18 xl:px-20 space-y-8 md:space-y-10 lg:space-y-12;
  h3 {
    @apply text-center;
  }
  .facts-wrapper {
    @apply flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-18 xl:space-x-32 content-center justify-center items-center justify-items-center;

    .fact {
      @apply flex flex-col font-neueHaasUnica content-center justify-items-center justify-center items-center;

      .number {
        @apply font-normal font-publicSans text-primary text-[50px] lg:text-[80px] leading-[45px] lg:leading-[72px] tracking-[-0.04em];
      }

      .label {
        @apply font-semibold text-accentOrange text-[16px] leading-6;
      }
    }
  }
}
</style>

<template>
  <section class="quote-gallery-section">
    <div class="inner-container">
      <h2 class="title" v-if="title !== ''">{{ title }}</h2>
      <div class="top-quote-only" v-if="bottomQuote.quote === ''">
        <div class="tq-content quote-text">
          <div v-html="topQuote.quote"></div>
        </div>
        <div class="tq-content quote-author text-right">
          <p>{{ topQuote.quote_author }}</p>
        </div>
      </div>
      <div class="gallery-with-quote">
        <div class="gallery-item top-quote" v-if="bottomQuote.quote !== ''">
          <div class="quote-text">
            <div v-html="topQuote.quote"></div>
          </div>
          <div class="quote-author text-right">
            <p>{{ topQuote.quote_author }}</p>
          </div>
        </div>
        <div
          class="gallery-item gallery-image"
          v-for="(image, index) of gallery"
          :key="index"
          :class="`${(index + 1) % 4 === 0 ? '!w-full' : ''} ${image.caption ? 'caption': ''}`"
        >
        <template v-if="image.caption !== ''">
          <p>{{image.caption}}</p>
          <img :src="image.url" alt="" class="image" />
        </template>
        <template v-else>
          <img :src="image.url" alt="" class="image" />
        </template>
        </div>
      </div>
      <div class="bottom-quote-only" v-if="bottomQuote.quote !== ''">
        <div class="bq-content quote-text">
          <div v-html="bottomQuote.quote"></div>
        </div>
        <div class="bq-content quote-author text-right">
          <p>{{ bottomQuote.quote_author }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, onUpdated, onBeforeUnmount, ref } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const props = defineProps(['data'])

const title = ref(props.data.title)
const topQuote = ref(props.data.quote_section.top_quote)
const bottomQuote = ref(props.data.quote_section.bottom_quote)
const gallery = ref(props.data.gallery)

const animate = () => {
  gsap.registerPlugin(ScrollTrigger)
  gsap.set('.quote-gallery-section .gallery-item', { autoAlpha: 0, y: 20 })
  ScrollTrigger.batch('.quote-gallery-section .gallery-item', {
    scroller: '[data-scroll-container]',
    onEnter: (batch) => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.1 }),
    start: 'top 70%',
    end: 'bottom 70%',
    markers: false
  })
  if (bottomQuote.value.quote === '') {
    gsap.set('.top-quote-only .tq-content', { autoAlpha: 0, y: 20 })
    ScrollTrigger.batch('.top-quote-only .tq-content', {
      scroller: '[data-scroll-container]',
      onEnter: (batch) => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.1 }),
      start: 'top 70%',
      end: 'bottom 70%',
      markers: false
    })
  } else {
    gsap.set('.bottom-quote-only .bq-content', { autoAlpha: 0, y: 20 })
    ScrollTrigger.batch('.bottom-quote-only .bq-content', {
      scroller: '[data-scroll-container]',
      onEnter: (batch) => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.1 }),
      start: 'top 50%',
      end: 'bottom 50%',
      markers: false
    })
  }
}

onMounted(() => {
  animate()
  ScrollTrigger.refresh()
})

onUpdated(() => {
  ScrollTrigger.refresh()
})

onBeforeUnmount(() => {
  ScrollTrigger.getAll().forEach((trigger) => {
    trigger.kill()
  })
})
</script>
<style lang="scss" scoped>
.quote-gallery-section {
  @apply w-full max-w-screen-xl mx-auto px-6 xl: px-0 py-10;

  .inner-container {
    .title {
      @apply text-[48px] pt-10 pb-20;
    }

    .top-quote-only {
      @apply bg-text p-5 lg: p-25 min-h-[550px] flex flex-col justify-center;

      .quote-text {
        @apply text-white;

        div:deep() {
          @apply text-[60px] lg:text-[60px] leading-tight lg:leading-20 mb-15;
            p {
              @apply text-[60px] lg:text-[60px] leading-tight lg:leading-20 mb-15;
            }
        }
      }

      .quote-author {
        @apply text-white mr-12;
      }
    }

    .gallery-with-quote {
      @apply flex flex-col lg: flex-row flex-wrap gap-3 mt-3;

      .top-quote {
        @apply bg-white min-h-[450px] w-full lg: w-[49%] flex flex-col justify-center;

        .quote-text {
          @apply text-primary;

          div:deep() {
          @apply mb-10 leading-14;
            p {
              @apply text-[48px] lg:text-[60px] mb-15;
            }
          }
        }

        .quote-author {
          @apply text-text mr-12;
        }
      }

      .gallery-image {
        @apply w-full lg: w-[49%] h-450px max-h-[450px] relative flex-grow;
        &.caption {
          @apply text-text;
          &:hover p {
            @apply transition-opacity duration-300 ease-in-out opacity-100;
          }
          &:hover::before {
            @apply transition-opacity duration-300 ease-in-out opacity-50;
          }
          &:before {
            @apply bg-primary opacity-0 bg-black content-[''] absolute top-0 left-0 right-0 bottom-0 w-full z-1 h-full transition-opacity duration-300 ease-in-out;
          }
          p {
            @apply text-white absolute bottom-5 left-4 z-1 transition-opacity duration-300 opacity-0 ease-in-out;
          }
        }

        img {
          @apply object-cover object-center w-full h-full;
        }
      }
    }

    .bottom-quote-only {
      @apply bg-white p-5 lg: p-25 min-h-[550px] flex flex-col justify-center;

      .quote-text {
        @apply text-text;

        div:deep() {
          @apply text-[48px] lg:text-[60px] mb-15;
          p {
            @apply text-[48px] lg:text-[60px] mb-15;
          }
        }
      }

      .quote-author {
        @apply text-text mr-12;
      }
    }
  }
}
</style>

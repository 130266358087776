<template>
  <div class="post-meta">
    <archive-link
      :archive-id="author"
      archive-type="users"
      class="post-author"
    />
  </div>
</template>

<script>
import ArchiveLink from '@/components/utility/ArchiveLink.vue'

export default {
  name: 'PostMeta',
  components: { ArchiveLink },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      author: this.post.author
    }
  },
}
</script>

<style>

</style>

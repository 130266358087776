<template>
  <section class="locations-section main-container" >
    <div class="inner-container">
      <h3 class="title" id="locations-title">{{ data.title }}</h3>

      <div class="locations-container">
        <div
          :class="idx === 0 ? 'location-1' : 'location'"
          v-for="(location, idx) in data.locations"
          :key="idx"
        >
          <img class="location-img" :src="location.location_image.url" />
          <div class="location-data">
            <div class="location-data-idx">
              {{ idx + 1 }}
            </div>
            <h4>{{ location.location_name }}</h4>
            <span class="location-data-name-business">
              <strong>{{ location.name }} - </strong> {{ location.business }}
            </span>
            <div class="location-data-info">
              <p v-html="location.address" />
              <p>tel: {{ location.telephone }} {{ location.fax ? `| fax: ${location.fax}` : '' }}</p>
            </div>
            <Button
              class="gsap_text"
              :text="'View on Map'"
              color="green"
              :func="goToMap"
              :args="idx"
            ></Button>
          </div>
        </div>
      </div>
      <div class="map-container" id="map">
        <GMapMap
          class="google-map"
          :center="computedCenter"
          :options="mapStyle"
          :zoom="10.5"
          :style="{
            width: '100%',
            'min-height': screenW > 1024 ? '670px' : '317px',
          }"
        >
          <GMapCluster :zoomOnClick="true">
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              :icon="markerIcon"
              :label="{
                color: '#fff',
                fontSize: '15px',
                fontWeight: '600',
                text: `${index + 1}`,
              }"
              @click="center = m.position"
            />
          </GMapCluster>
        </GMapMap>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/utility/Button.vue'
import gmapMarker from '@/assets/img/gmap_marker.png'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  data () {
    return {
      focusedCity: 0,
      mapStyle: {
        styles: [
          {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#444444'
              }
            ]
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f2f2f2'
              }
            ]
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e6eeea'
              }
            ]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e6eeea'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: -100
              },
              {
                lightness: 45
              },
              {
                color: '#d4dcd8'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified'
              }
            ]
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#a3c4bb'
              },
              {
                visibility: 'on'
              }
            ]
          }
        ]
      },
      markerIcon: gmapMarker
    }
  },
  computed: {
    markers () {
      // get lat or long:
      // data.locations[idx].location.lat || lng
      const coordinates = []
      for (const location of this.data.locations) {
        const coordsObj = {
          position: {
            lat: location.location.lat,
            lng: location.location.lng
          }
        }
        coordinates.push(coordsObj)
      }
      return coordinates
    },
    computedCenter () {
      return {
        lat: this.markers[this.focusedCity].position.lat,
        lng: this.markers[this.focusedCity].position.lng
      }
    },
    screenW () {
      return window.screen.width
    }
  },
  methods: {
    goToMap (idx) {
      const element = document.getElementById('map')
      this.$root.locoScroll.scrollTo(element)
      //   this.$smoothScroll({
      //     scrollTo: element, // scrollTo is also allowed to be number
      //   });
      // element.scrollIntoView(false);
      const thisCoords = {
        lat: this.data.locations[idx].location.lat,
        lng: this.data.locations[idx].location.lng
      }
      this.focusedCity = idx
    }
  },
  props: ['data'],
  components: {
    Button
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    /*     gsap.fromTo(
      '#locations-title',
      { x: -10, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: '.locations-section',
          scroller: '[data-scroll-container]',
          start: 'top center',
          end: 'bottom center'
        },
        x: 0,
        delay: 0,
        duration: 0.3,
        autoAlpha: 1,
        stagger: 1
      }
    ) */
    /* gsap.fromTo(
      '.location',
      { x: -10, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: '.location',
          scroller: '[data-scroll-container]',
          start: '-100px center',
          end: 'bottom center'

        },
        x: 0,
        delay: 0,
        duration: 0.3,
        autoAlpha: 1,
        stagger: 1
      }
    ) */
  }
}
</script>

<style lang='scss' scoped>
.main-container {
  @apply h-[100vh] h-full;
  .inner-container {
    @apply w-full mx-auto max-w-screen-xl px-4 py-16 lg:h-full;
    .title {
      @apply pb-16;
    }
    .map-container {
      @apply w-full min-h-[317px] my-[75px] lg:min-h-[670px];
      .google-map {
      }
    }
    .locations-container {
      @apply flex flex-col gap-[40px];
      .location-1 {
        @apply border-b-2 border-secondaryGray flex flex-col lg:flex-row-reverse lg:pb-[40px];
        &-img {
          @apply object-cover w-full lg:w-[60%] xl:w-[65%] lg:min-w-[700px] lg:min-h-[375px] lg:max-h-[375px];
        }
        &-data {
          @apply px-16 py-10 relative flex flex-col gap-[14px] w-full lg:w-[40%];
          h4 {
            @apply font-medium;
          }
          &-name-business {
            @apply text-[20px];
          }
          &-info {
            p {
            }
          }
          &-idx {
            @apply bg-primaryShade text-white w-[34px] h-[34px] flex items-center justify-center
                absolute left-0;
          }
        }
        &:last-child {
          @apply border-none;
        }
      }
      .location {
        @apply border-b-2 border-secondaryGray flex flex-col lg:flex-row-reverse lg:pb-[40px];
        &-img {
          @apply object-cover w-full lg:w-[60%] xl:w-[65%] lg:min-w-[700px] lg:min-h-[375px] lg:max-h-[375px];
        }
        &-data {
          @apply px-16 py-10 relative flex flex-col gap-[14px] w-full lg:w-[40%];
          h4 {
            @apply font-medium;
          }
          &-name-business {
            @apply text-[20px];
          }
          &-info {
            p {
            }
          }
          &-idx {
            @apply bg-primaryShade text-white w-[34px] h-[34px] flex items-center justify-center
                absolute left-0;
          }
        }
        &:last-child {
          @apply border-none;
        }
      }
    }
  }
}
</style>

<template>
  <section class="split">
    <div class="split-left">
      <div class="split-left-inner">
        <span class="split-left-inner-headline gsap_textSplit">{{ data.headline }}</span>
        <h2 class="split-left-inner-h2 gsap_textSplit">{{ data.title }}</h2>
        <p class="split-left-inner-subtitle gsap_textSplit">{{ data.sub_title }}</p>
        <p class="split-left-inner-content gsap_textSplit">{{ data.content }}</p>
        <ButtonComp
        v-if="data.link.url"
          class="split-left-inner-button gsap_textSplit"
          :text="data.link.title"
          :url="data.link.url"
          :target="data.link.target"
          color="green"
        ></ButtonComp>
      </div>
    </div>
    <div class="split-right">
      <img :src="data.image.url" alt="" />
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ButtonComp from '@/components/utility/Button.vue'
export default {
  name: 'SplitScreenHero',
  components: { ButtonComp },
  props: ['data'],
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.gsap_textSplit',
      { x: 100, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: '.split',
          scroller: '[data-scroll-container]',
          start: 'top 60%',
          end: 'botton center'
        },
        stagger: 0.2,
        ease: 'easyInOut',
        x: 0,
        autoAlpha: 1
      }
    )
    gsap.fromTo(
      '.split-right img',
      { autoAlpha: 0, scale: 1.1 },
      {
        scrollTrigger: {
          trigger: '.split-right',
          start: '-20px center',
          scroller: '[data-scroll-container]',
          end: 'center center'

        },
        ease: 'easyInOut',

        stagger: 0.2,

        scale: 1,
        duration: 1,
        force3D: true,
        autoAlpha: 1
      }
    )
    ScrollTrigger.refresh()
  }
}
</script>

<style lang="scss" scoped>
.split {
  @apply w-full flex flex-col lg:flex-row justify-between;
  &-left {
    @apply w-full lg:w-1/2 lg:max-w-1/2 flex flex-row justify-end pl-0 md:pl-5;
    &-inner {
      @apply flex flex-col justify-center w-full lg:w-screen-sm px-6 lg:px-0 pr-6 lg:pr-14 py-10;
      &-headline {
        @apply text-lg lg:text-3xl text-accentOrange mb-4;
      }
      &-h2 {
        @apply text-4xl lg:text-6xl mb-4;
      }
      &-subtitle {
        @apply text-sm lg:text-lg mb-6 uppercase;
      }
      &-content {
        @apply text-base lg:text-lg text-text font-light mb-6;
      }
    }
  }
  &-right {
    @apply flex flex-col items-center justify-center w-full overflow-hidden;
    img {
      @apply w-full h-full object-cover object-center;
    }
  }
}
</style>

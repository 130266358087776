<template>
  <section v-if="relatedProjects.length > 0" class="related-projects-section">
    <div class="related-projects-inner">
      <h3>Related Projects</h3>
      <div class="related-projects-wrapper">
        <div
          class="item-related-project"
          v-for="(itemProject, indexProject) in relatedProjects"
          :key="indexProject"
        >
          <div
            :style="{
              'background-image': `url(${itemProject.acf.hero_section.project_hero_image.url})`,
              'backgroundSize': 'cover',
              'height': '100%',
            }"
            class="item-image"
          >
            <div class="box">
              <span class="project-category">Recent Projects</span>
              <h3 class="project-name" v-html="itemProject.title.rendered"></h3>

              <Button
                class="gsap_text"
                text="See Project"
                :url="itemProject.link"
                target="self"
                color="white"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/utility/Button.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  components: {
    Button
  },
  props: ['categories', 'exclude'],
  data () {
    return {
      animationAdded: false,
      relatedProjects: [],
      relatedProjectRequest: {
        type: 'projects',
        params: {
          projects_categories: '',
          page: 1,
          per_page: 2,
          exclude: '',
          orderby: 'date',
          order: 'desc'
        },
        showLoading: true
      }
    }
  },
  methods: {
    getRelatedProjects () {
      if (this.categories.length === 0) {
        return
      }

      this.relatedProjectRequest.params.projects_categories =
        this.categories.toString()
      this.relatedProjectRequest.params.exclude = this.exclude
      this.$store.dispatch('getItems', this.relatedProjectRequest).then(() => {
        this.updateRelatedProjects()
      })
    },
    updateRelatedProjects () {
      this.relatedProjects = this.$store.getters.requestedItems(
        this.relatedProjectRequest
      )
    },
    animateRelatedProjects () {
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(
        '.related-projects-inner .item-related-project',
        { x: -20, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: '.related-projects-inner',
            scroller: '[data-scroll-container]',
            start: 'top center',
            end: 'bottom center'
          },
          x: 0,
          duration: 0.5,
          autoAlpha: 1,
          stagger: 0.5
        }
      )
      gsap.set('.related-projects-section .item-related-project', {
        autoAlpha: 0,
        y: 20
      })
    }
  },
  created () {
    this.getRelatedProjects()
  },
  updated () {
    if (!this.animationAdded) {
      this.animateRelatedProjects()
      this.animationAdded = true
    }
  }
}
</script>
<style lang="scss" scoped>
.related-projects-section {
  @apply pt-8;
  .related-projects-inner {
    @apply flex flex-col space-y-6;

    .related-projects-wrapper {
      @apply flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0;
      .item-related-project {
        @apply relative overflow-hidden w-full lg:w-1/2;
        .item-image {
          @apply w-full h-auto bg-cover bg-no-repeat bg-cover bg-center min-h-[35vh] lg:min-h-[45vh] flex flex-col p-4 lg:p-8 justify-end;

          .box {
            @apply w-3/4 lg:w-1/2 z-9;
            .project-category {
              @apply font-neueHaasUnica font-normal text-xs text-white leading-6;
            }
            .project-name {
              @apply font-neueHaasUnica my-2 lg:my-6 text-white text-3xl font-normal leading-10 md:text-5xl md:leading-[52px];
            }
            .project-content {
              @apply font-neueHaasUnica font-normal text-sm text-white leading-6;
            }
          }
        }

        &:before {
          @apply absolute w-full h-full bg-gradient-to-b to-text from-primary/0 opacity-60 left-0 top-0 content-[''];
        }
      }
    }
  }
}
</style>

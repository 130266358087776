<template>
  <div class="wrapper">
    <main>
      <article v-if="post" class="single-post">
        <div class="hero" :style="{'backgroundImage': `url('${post.featured_image.large}')`}">
          <div class="title-container">
            <p class="date">{{ formatDate(post.date) }}</p>
            <h1 class="title" v-html="post.title.rendered" />
          </div>
        </div>
        <div class="post-info-container">
          <div class="post-info">
            <div class="left-info">
              <p class="author">By: {{post.author.name}}</p>
              <p class="see-more-stories"><a href="/stories">See More stories</a></p>
            </div>
            <div class="right-info">
              <div class="categories">
                <p class="cat-title" @click="catToggle">Categories: <span class="pl-2 text-[14px]">▼</span></p>
                  <slide-up-down v-model="catActive" :duration="500">
                    <ul class="cat-list">
                      <li v-for="cat in post.categories_info" :key="cat.id">
                        <a :href="`/stories/#${cat.slug}`">{{cat.name}}</a>
                      </li>
                    </ul>
                  </slide-up-down>
              </div>
              <div class="related">
                <p class="rel-title" @click="relToggle">Related: <span class="pl-2 text-[14px]">▼</span></p>
                  <slide-up-down v-model="relActive" :duration="500">
                    <ul class="rel-list">
                      <li v-for="rel in post.related_posts" :key="rel.id">
                        <a :href="rel.link">{{rel.title}}</a>
                      </li>
                    </ul>
                  </slide-up-down>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container">
          <div class="content px-6 lg:px-0" v-html="post.content.rendered" />
        </div>
        <div class="gallery-container" v-if="post && post.acf.post_gallery">
          <div class="gallery-inner-container">
            <div class="the-gallery">
              <div class="left-side">
                <VueAgile ref="leftImages" :dots="false" :navButtons="false">
                  <div class="left-slide" v-for="(leftSlide, idx) of post.acf.gallery" :key="idx">
                    <img :src="leftSlide.image.url" :alt="leftSlide.image.alt" />
                  </div>
                </VueAgile>
              </div>
              <div class="right-side">
                <div class="right-side-inner">
                <VueAgile ref="rightContent" :dots="false" :navButtons="false">
                  <div class="right-side-content" v-for="(leftSlide, idx) of post.acf.gallery" :key="idx">
                    <div v-html="leftSlide.content" />
                  </div>
                </VueAgile>
                </div>
                <VueAgile class="thumb-gall mb-4" :centerMode="true" ref="thumbs" :as-nav-for="[$refs.rightContent, $refs.leftImages]" :dots="false" :slidesToShow="3" :navButtons="false">
                  <div class="right-side-content-thumbnails" v-for="(leftSlide, idx) of post.acf.gallery" :key="idx">
                    <img :src="leftSlide.image.url" :alt="leftSlide.image.alt" />
                  </div>
                </VueAgile>
                <button @click="$refs.thumbs.goToPrev()">
                  <img class="h-12" src="@/assets/img/nav-left.svg" alt="next" />
                </button>
                <button class="ml-4" @click="$refs.thumbs.goToNext()">
                  <img class="h-12" src="@/assets/img/nav-right.svg" alt="next" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </main>
  </div>
</template>

<script>
import { format } from 'date-fns'
import SlideUpDown from 'vue3-slide-up-down'
import { VueAgile } from 'vue-agile'
import 'vue-agile/dist/VueAgile.css'

export default {
  name: 'Single',
  components: {
    SlideUpDown,
    VueAgile
  },
  props: {
    slug: {
      type: String,
      required: false
    },
    newsSlug: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      request: {
        type: 'posts',
        slug: this.slug,
        showLoading: true
      },
      date: '',
      relatedPosts: [],
      catActive: false,
      relActive: false
    }
  },
  computed: {
    post () {
      return this.$store.getters.singleBySlug(this.request)
    },
    async readingTime () {
      const words = await this.post.content.rendered.split(' ').length + 1
      return `${Math.ceil(words / 200)} min read`
    },
    getDate: {
      async get () {
        const date = await this.post
        return format(new Date(date.date), 'MMMM dd, yyyy')
      },
      default: 'Loading...'
    }
  },
  methods: {
    catToggle () {
      this.catActive = !this.catActive
      this.relActive = false
    },
    relToggle () {
      this.relActive = !this.relActive
      this.catActive = false
    },
    formatDate (date) {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear().toString().substring(2, 4)

      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }

      return [month, day, year].join('/')
    },
    sortData (data) {
      return {
        info_card_headline: data.title.rendered ? data.title.rendered : 'Larry H. Miller',
        info_card_content: data.excerpt.rendered ? data.excerpt.rendered : 'Larry H. Miller',
        card_image: { url: data.featured_image.medium },
        info_card_date: data.date,
        info_card_cta_button: { title: 'Read more', url: data.link }
      }
    },
    getPost () {
      this.$store.dispatch('getSingleBySlug', this.request).then(() => {
        if (this.post) {
          this.$store.dispatch('updateDocTitle', {
            parts: [
              this.$root.decodeTitle(this.post.title.rendered),
              this.$store.state.site.name
            ]
          })

          this.date = format(new Date(this.post.date), 'd MMMM yyyy')

          const relatedRequest = {
            type: 'posts',
            params: {
              categories: this.post.categories,
              exclude: this.post.id,
              per_page: 3,
              fields:
                'id,slug,title,featured_media,link,author,categories,excerpt,better_featured_image,date,modified,content'
            },
            showLoading: false
          }

          this.$store.dispatch('getItems', relatedRequest).then(() => {
            this.relatedPosts = this.$store.getters.requestedItems(relatedRequest)
          })
        }
      }).then(() => {
        // this.$gtag.pageview({
        //   page_path: this.$root.convertToRelative(this.post.link),
        //   page_title: document.title,
        //   page_location: window.location.href,
        // })
      })
    }
  },
  created () {
    this.getPost()
  },
  updated () {
    // console.log(this.post);
    // console.log(this.relatedPosts);
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
main:deep() {
  @apply ;
  .single-post {
    @apply prose max-w-full;
    .post-info-container {
      @apply bg-darkBg;
      .post-info {
        @apply w-full max-w-screen-xl mx-auto px-6 xl:px-0 flex flex-col lg:flex-row items-center justify-between;
          .left-info {
            @apply flex flex-row items-center;
          .author {
            @apply mr-10 m-0 text-[20px];
          }
          .see-more-stories {
            @apply text-white pl-6 border-l border-solid border-l-white m-4 py-4;
            a {
              @apply text-white no-underline cursor-pointer text-[20px];
            }
          }
        }
        .right-info {
          @apply flex;
          .categories {
            @apply relative cursor-pointer mr-6;
            .slide-up-down__container {
                @apply bg-darkBg absolute w-70 shadow-lg left-0 md:-left-[60%] xl:-left-[60%];
                .cat-list {
                  @apply flex flex-col my-0;
                  li {
                    @apply text-white no-underline cursor-pointer py-4 my-0;
                    &:not(:last-child) {
                      @apply border-b border-b-white/40;
                    }
                    a {
                      @apply text-white no-underline cursor-pointer;
                    }
                  }
                }
            }
          }
          .related {
            @apply relative cursor-pointer;
            .slide-up-down__container {
                @apply bg-darkBg absolute w-70 shadow-lg -left-[160%] xl:-left-[100%];
                .rel-list {
                  @apply flex flex-col my-0;
                  li {
                    @apply text-white no-underline cursor-pointer py-4 my-0;
                    &:not(:last-child) {
                      @apply border-b border-b-white/40;
                    }
                    &::hover {
                      @apply !bg-primary;
                    }
                    a {
                      @apply text-white no-underline cursor-pointer;
                    }
                  }
                }
            }
          }
        }
        * {
          @apply text-white;
        }
      }
    }
    .hero {
      @apply h-[80vh] bg-no-repeat bg-cover relative bg-primary ;
      &::after {
        @apply bg-gradient-to-b from-black/0 via-black/40 to-black/70 content-[''];
        @apply w-full h-full absolute top-0 left-0 right-0 bottom-0 z-1;
      }
      .title-container {
        @apply w-full h-full max-w-screen-xl px-6 xl:px-0 mx-auto flex flex-col justify-end z-2 relative;
        h1 {
          @apply font-normal text-[36px] lg:text-[48px] mb-4 text-white mb-45 w-full lg:w-6/12 leading-tight;
        }
        p {
          @apply text-white text-[12px];
        }
      }
    }
    .content-container {
      @apply w-full max-w-screen-xl mx-auto p-6 xl:p-0;
      .content {
        @apply max-w-[1130px] mx-auto;
        a{
          @apply text-text text-[20px] cursor-pointer font-weight-light;
        }
      }
    }

    .figcaption {
      @apply text-center;
    }
    p {
      @apply text-text text-[20px] font-weight-light leading-tight;
    }
    img {
      @apply my-3;
    }
    a {
      @apply text-primary underline-primary;
    }
    ol,
    ul {
      li {
        @apply text-text text-[16px] font-light leading-6 font-publicSans;
        strong {
          @apply text-text;
        }
        b {
          @apply text-text;
        }
        p {
          @apply text-text text-[16px] font-thin;

          b {
            @apply text-text  text-[16px] font-normal;
          }
        }
      }
    }
    ul > li::before {
      @apply !bg-text;
    }
    .wp-block-gallery {
      @apply mx-0;
      .blocks-gallery-grid {
        @apply flex;
        .blocks-gallery-item {
          @apply w-[33%] p-0 m-0;
          &::before {
            @apply hidden;
          }
          figure {
            @apply m-0 p-2 w-full h-full;
            img {
              @apply object-cover h-full w-full;
            }
          }
        }
      }
    }
    blockquote {
      @apply text-center border-none w-full mx-auto;
      p {
        @apply font-publicSans text-black mt-10 text-center text-[36px] lg:text-[55px] pl-0 not-italic font-light leading-tight lg:leading-15 mb-10;
      }
      strong {
        @apply font-publicSans text-black mt-10 text-center text-[36px] lg:text-[55px] pl-0 not-italic font-light leading-tight lg:leading-15 mb-10;
      }
      ::before,
      ::after {
        @apply hidden;
      }
      cite {
        @apply text-primary text-center mb-10 not-italic text-[16px];
        p {
          @apply text-primary text-center mb-10 not-italic text-[16px];
        }
      }
    }
    .quote-ref {
      @apply text-primary text-center mb-10;
    }

    .wp-block-columns {
      @apply flex flex-col lg:flex-row gap-0 lg:gap-6 mb-4;
      .wp-block-column {
        @apply w-full;
      }
    }

    .wp-block-audio {
      @apply mx-0;
    }

    audio {
      @apply rounded-none bg-primary h-[80px] w-full;
    }

    audio::-webkit-media-controls {
      @apply px-6 py-4;
    }

    audio::-webkit-media-controls-panel {
      @apply bg-primary;
    }

    audio::-webkit-media-controls-enclosure {
      @apply bg-primary;
    }

    audio::-webkit-media-controls-play-button {
      @apply bg-white rounded-full;
    }

    audio::-webkit-media-controls-mute-button,
    audio::-webkit-media-controls-volume-slider-container,
    audio::-webkit-media-controls-volume-slider,
    audio::-webkit-media-controls-timeline {
      @apply filter invert;
    }

    audio::-webkit-media-controls-mute-button {
      @apply hidden;
    }

    audio::-webkit-media-controls-current-time-display,
    audio::-webkit-media-controls-time-remaining-display {
      @apply hidden;
    }

    .wp-block-video {
      @apply w-full !mx-auto relative py-6;
    }

    .content {
      @apply mb-10 mt-10 lg:mt-10;
    }

    .gallery-container {
      @apply bg-primary;
      .gallery-inner-container {
        @apply w-full mx-auto;
        .the-gallery {
          @apply grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-10;
        .left-side {
          @apply col-span-1 lg:col-span-7;
          .left-slide {
            @apply max-h-[250px] lg:max-h-[500px] h-[500px];
            img {
              @apply object-cover h-full w-full m-0;
            }
          }
        }
        .right-side {
          @apply col-span-1 lg:col-span-5 px-6 lg:px-0;
          @apply max-h-[500px] h-[500px] overflow-hidden;
          .right-side-inner {
            @apply max-w-[470px] pr-0 lg:pr-2;
            .right-side-content {
              div {
                @apply ;
                p {
                  @apply text-white;
                }
              }
            }
          }

          .thumb-gall {
            .right-side-content-thumbnails {
              &.agile__slide--active {
                @apply ;
                img {
                  @apply border border-solid border-white border-[5px];
                }
              }
              @apply h-[105px] pr-5;
              img {
                @apply object-cover object-center h-full w-full m-0 ;
              }
            }
          }
        }
        }
      }
    }
  }
}
</style>

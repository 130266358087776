<template>
  <div class="wrapper" ref="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-for="(module, index) in page.acf.modules">
          <template v-if="module.acf_fc_layout === 'forms'">
            <Forms :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'imageModule'">
            <ImageComponent :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'heroSection'">
            <Hero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'news'">
            <News :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'featuredProject'">
            <FeaturedProject :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'imageCTA'">
            <ImageCTA :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'spacermodule'">
            <SpacerComponent :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'verticalScrollingTabs'">
            <VerticalScrollingTabs :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'logosGallery'">
            <LogosGallery :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'text_list'">
            <TextList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'banner_cta'">
            <BannerCTA :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'vertical_tabs'">
            <VerticalTabs :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'split_screen_hero'">
            <SplitScreenHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'locations'">
            <Locations :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'projects'">
            <Projects :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'quote'">
            <Quote :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'facts'">
            <Facts :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'quote&gallery'">
            <QuoteGallery :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'employees'">
            <Employees :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'stories'">
            <Stories :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'awards'">
            <Awards :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'values'">
            <OurValues :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'htmlEditor'">
            <HTMLEditor :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'instagramNews'">
            <InstagramNews :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'downloadBanner'">
            <DownloadBanner :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'mediaGallery'">
            <MediaGallery :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'videoSection'">
            <VideoSection :key="index" :data="module" :index="index" />
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script>
import Forms from '@/components/modules/Forms.vue'
import ImageComponent from '@/components/modules/ImageComponent.vue'
import Hero from '@/components/modules/Hero.vue'
import News from '@/components/modules/News.vue'
import FeaturedProject from '@/components/modules/FeaturedProject.vue'
import ImageCTA from '@/components/modules/ImageCTA.vue'
import SpacerComponent from '@/components/modules/Spacer.vue'
import VerticalScrollingTabs from '@/components/modules/VerticalScrollingTabs.vue'
import LogosGallery from '@/components/modules/LogosGallery.vue'
import TextList from '@/components/modules/TextList.vue'
import BannerCTA from '@/components/modules/BannerCTA.vue'
import VerticalTabs from '@/components/modules/VerticalTabs.vue'
import SplitScreenHero from '@/components/modules/SplitScreenHero.vue'
import Locations from '@/components/modules/Locations.vue'
import Projects from '@/components/modules/Projects.vue'
import Quote from '@/components/modules/Quote.vue'
import Facts from '@/components/modules/Facts.vue'
import QuoteGallery from '@/components/modules/QuoteGallery.vue'
import Employees from '@/components/modules/Employees.vue'
import Stories from '@/components/modules/Stories.vue'
import Awards from '@/components/modules/AwardCards.vue'
import OurValues from '@/components/modules/OurValues.vue'
import HTMLEditor from '@/components/modules/HTMLEditor.vue'
import InstagramNews from '@/components/modules/InstagramNews.vue'
import DownloadBanner from '@/components/modules/DownloadBanner.vue'
import MediaGallery from '@/components/modules/MediaGallery.vue'
import VideoSection from '@/components/modules/VideoSection.vue'

export default {
  components: {
    Forms,
    ImageComponent,
    Hero,
    News,
    FeaturedProject,
    ImageCTA,
    SpacerComponent,
    VerticalScrollingTabs,
    LogosGallery,
    TextList,
    BannerCTA,
    VerticalTabs,
    SplitScreenHero,
    Locations,
    Projects,
    Quote,
    Facts,
    QuoteGallery,
    Employees,
    Stories,
    Awards,
    OurValues,
    HTMLEditor,
    InstagramNews,
    DownloadBanner,
    MediaGallery,
    VideoSection
  },
  name: 'mainPage',
  props: {
    slug: {
      type: String,
      required: true
    },
    isHomepage: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      request: {
        type: 'pages',
        slug: this.slug,
        showLoading: true
      },
      introShown: false
    }
  },
  computed: {
    page () {
      return this.$store.getters.singleBySlug(this.request)
    }
  },
  methods: {
    getPage () {
      this.$store
        .dispatch('getSingleBySlug', this.request)
        .then(() => {
          if (this.page) {
            if (!this.isHomepage) {
              this.$store.dispatch('updateDocTitle', {
                parts: [
                  this.$root.decodeTitle(this.page.title.rendered),
                  this.$store.state.site.name
                ]
              })
            } else {
              this.$store.dispatch('updateDocTitle', {
                parts: [null, this.$store.state.site.name]
              })
            }
          } else {
            this.$router.replace('/404')
          }
        })
        .then(() => {
          // this.$gtag.pageview({
          //   page_path: this.$root.convertToRelative(this.page.link),
          //   page_title: document.title,
          //   page_location: window.location.href,
          // })
        })
    },
    introComplete () {
      this.introShown = true
      localStorage.setItem('introShown', true)
    },
    isTopHero () {
      if (!this.opHeroRan) {
        const arr = Object.values(this.page.acf.modules)
        if (arr[0].acf_fc_layout === 'heroSection') {
          this.$root.topHero = false
        } else {
          this.$root.recalculateHeight()
          this.$root.topHero = true
        }
      }
    },
    isPage () {
      if (typeof this.page === 'object') {
        this.isTopHero()
      } else {
        setTimeout(() => {
          this.isPage()
        }, 100)
      }
    }
  },
  created () {
    this.getPage()
  },
  mounted () {
    const anchor = this.$router.currentRoute.hash
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor
      }
    })
  },
  updated () {
    this.isPage()
  }
}
</script>

<style lang="scss">
</style>

<template>
  <section ref="mainContainer" class="values-container">
    <div class="inner-container">
      <div class="content">
        <div class="left">
          <div class="title">
            <h2 v-if="props.data.top_area.top_title">{{ props.data.top_area.top_title }}</h2>
          </div>
          <div class="top-content" v-if="props.data.top_area.top_content" v-html="props.data.top_area.top_content" />
        </div>
        <div class="center">
          <div ref="animationContainer" class="anim-container" />
        </div>
        <div class="right">
          <div class="excellence">
            <h3>Excellence</h3>
            <div class="excellence-content" v-if="props.data.values_area.excellence"
              v-html="props.data.values_area.excellence" />
          </div>
          <div class="partnership">
            <h3>Partnership</h3>
            <div class="partnership-content" v-if="props.data.values_area.partnership"
              v-html="props.data.values_area.partnership" />
          </div>
          <div class="ownership">
            <h3>Ownership</h3>
            <div class="ownership-content" v-if="props.data.values_area.ownership"
              v-html="props.data.values_area.ownership" />
          </div>
        </div>
      </div>
      <div class="quote-area">
        <div class="quote" v-if="props.data.quote_area.quote" v-html="props.data.quote_area.quote" />
        <div class="quote-author text-right">
          <p v-if="props.data.quote_area.quote_author">{{ props.data.quote_area.quote_author }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ref, onMounted, getCurrentInstance, onUpdated, onBeforeUnmount } from 'vue'
import LottieWeb from 'lottie-web'
import Values from '@/assets/lottie/Values.json'

gsap.registerPlugin(ScrollTrigger)

const animationContainer = ref(null)
const animation = ref(null)
const mainContainer = ref(null)
const curr = getCurrentInstance()

const props = defineProps([
  'data'
])

onMounted(() => {
  const anim = LottieWeb.loadAnimation({
    container: animationContainer.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: Values,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMin slice'
    }
  })
  animation.value = anim
  coreValuesAnim()
})

onUpdated(() => {
  ScrollTrigger.refresh()
  curr.root.data.locoScroll.update()
}
)

onBeforeUnmount(() => {
  animation.value.destroy()
  ScrollTrigger.getAll().forEach(trigger => {
    trigger.kill()
  })
  ScrollTrigger.clearMatchMedia()
})

const coreValuesAnim = () => {
  gsap.fromTo(['.title', '.top-content'], {
    y: -20,
    opacity: 0
  }, {
    scrollTrigger: {
      trigger: '.content',
      scroller: '[data-scroll-container]',
      start: 'top center',
      markers: false,
      onUpdate: ({ progress }) => {
        setTimeout(() => {
          animation.value.play()
        }, 700)
      }
    },
    y: 0,
    opacity: 1,
    stagger: 0.5,
    duration: 1
  })

  gsap.fromTo(['.excellence', '.partnership', '.ownership'], {
    y: -20,
    opacity: 0
  }, {
    scrollTrigger: {
      trigger: '.content',
      scroller: '[data-scroll-container]',
      start: 'top center',
      markers: false,
      onUpdate: ({ progress }) => {
        setTimeout(() => {
          animation.value.play()
        }, 700)
      }
    },
    y: 0,
    opacity: 1,
    stagger: 0.5,
    duration: 1,
    delay: 3.5
  })

  gsap.fromTo(['.quote-area .quote', '.quote-area .quote-author'], {
    opacity: 0,
    y: 50,
    scale: 0.9
  }, {
    scrollTrigger: {
      trigger: '.quote-area',
      scroller: '[data-scroll-container]',
      start: 'top center',
      toggleActions: 'restart none none reverse',
      end: 'bottom center'

    },
    opacity: 1,
    y: 0,
    stagger: 1,
    scale: 1,
    duration: 1,
    ease: 'power3.out'
  })
}
</script>
<style lang="scss" scoped>
.values-container {
  @apply bg-[#191919] overflow-hidden;

  .inner-container {
    @apply w-full max-w-screen-xl mx-auto px-6 xl:px-0;

    .content {
      @apply grid grid-cols-1 lg:grid-cols-24 gap-0 lg:gap-6 border-b border-solid border-b-2 border-b-white pt-20;

      .left {
        @apply grid col-span-1 lg:col-span-7 flex flex-col mb-12;

        .title {
          h2 {
            @apply text-white text-[48px] font-light pb-6;
          }
        }

        .top-content {
          @apply text-white font-light pb-6;
        }
      }

      .center {
        @apply grid col-span-1 lg:col-span-11 flex flex-col mt-0 lg:mt-20 mb-12;

        .anim-container:deep() {
          @apply px-0 flex justify-center;

          svg {
            @apply max-w-full max-h-[500px] sm:max-h-full sm:max-w-[50%] lg:max-h-full lg:max-w-full;
          }
        }
      }

      .right {
        @apply grid col-span-1 lg:col-span-6 mt-0 lg:mt-40 flex flex-col;

        .excellence {
          @apply mb-4;

          h3 {
            @apply text-accentOrange text-[24px] mb-4 font-medium;
          }

          .excellence-content {
            @apply text-white font-light pb-6;
          }
        }

        .partnership {
          @apply mb-6;

          h3 {
            @apply text-accentOrange text-[24px] mb-4 font-medium;
          }

          .partnership-content {
            @apply text-white font-light pb-6;
          }
        }

        .ownership {
          @apply mb-6;

          h3 {
            @apply text-accentOrange text-[24px] mb-4 font-medium;
          }

          .ownership-content {
            @apply text-white font-light pb-6;
          }
        }
      }
    }

    .quote-area {
      @apply py-10 lg:py-40;

      .quote:deep(*) {
        @apply text-white mb-15 text-[40px] xl:text-[60px] leading-10 xl:leading-[60px];
      }

      .quote-author {
        @apply text-white uppercase;
      }
    }
  }
}
</style>

<template>
  <div class="awards-module">
    <div class="inner-container">
      <div class="left-side">
        <div v-if="data.sub_title" class="title">
          <p class="text-[12px] mb-4">{{data.sub_title}}</p>
          <h4 class="mb-4">{{ data.title }}</h4>
          <p>{{data.content}}</p>
          <Button v-if="data.button !== ''" :text="data.button.title" :url="data.button.url"
                :target="data.button.target" color="black"></Button>
        </div>
        <div v-else class="title">
          <h3>{{ data.title }}</h3>
        </div>
      </div>
      <div class="right-side">
        <div class="award-post" v-for="(post, index) in data.awards_cards" :key="index">
          <article class="award-post__item">
            <div class="award-post__item__img">
              <img :src="post.image.url" :alt="post.title" />
            </div>
            <div class="award-post__item__text">
              <span class="year">{{ post.year }}</span>
              <h3>{{ post.title }}</h3>

              <div class="content">{{ post.content }}</div>
              <Button v-if="post.button !== ''" :text="post.button.title" :url="post.button.url"
                :target="post.button.target" color="white"></Button>
            </div>
          </article>
        </div>
        <a v-if="data.bottom_button.url" :href="data.bottom_button.url" :target="data.bottom_button.target">
          <div class="bottom-button">
            {{ data.bottom_button.title }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import Button from '@/components/utility/Button.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

defineProps([
  'data'
])

onMounted(() => {
  // console.log(props.data)
  animateIt()
  ScrollTrigger.refresh()
})

const animateIt = () => {
  gsap.registerPlugin(ScrollTrigger)
  ScrollTrigger.refresh()
  const targets = gsap.utils.toArray('.award-post')
  gsap.fromTo(targets, {
    x: -80,
    autoAlpha: 0
  }, {
    scrollTrigger: {
      scroller: '[data-scroll-container]',
      trigger: '.awards-module',
      start: 'top center',
      end: 'bottom center',
      markers: false
    },
    x: 0,
    autoAlpha: 1,
    stagger: 0.1
  })
}

</script>
<style lang="scss" scoped>
.awards-module {
  @apply w-full max-w-screen-xl mx-auto px-6 xl: px-0 py-20;

  .inner-container {
    @apply grid grid-cols-1 lg: grid-cols-4;

    .left-side {
      @apply w-full;

      .title {
        @apply;

        h3 {
          @apply py-10 mt-0 lg: mt-30;
        }
      }
    }

    .right-side {
      @apply grid col-span-1 lg: col-span-3 grid-cols-1 lg:grid-cols-3 gap-4;

      .award-post {
        @apply col-span-1 w-full px-0 md: px-3 lg:p-0 flex flex-row justify-start justify-items-center lg:justify-center sm:justify-between overflow-x-auto lg:overflow-hidden;

        &__item:first-child {
          @apply;
        }

        &__item {
          @apply flex-1 bg-secondaryGray mb-4 snap-center;

          &__img {
            @apply w-full h-60 overflow-hidden;

            img {
              @apply h-full w-full object-cover;
            }
          }

          &__text {
            @apply text-white p-4;

            span {
              @apply text-[12px];
            }

            h3 {
              @apply text-xl mb-4 mt-2 line-clamp-1 leading-8;
            }

            .content {
              @apply font-light;
            }

            div:deep() {
              @apply line-clamp-3 min-h-[4.5rem] max-h-[4.5rem] overflow-hidden;

              * {
                @apply my-0 text-base font-normal leading-6;
              }

              *:not(:first-child) {
                @apply hidden;
              }
            }
          }
        }
      }

      .bottom-button {
        @apply text-white px-14 py-4 bg-darkBg text-center w-max;
      }
    }
  }
}
</style>

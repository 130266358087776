<template>
  <section
    class="featured-project"
    :class="uid"
    :style="`background-color: ${data.project_content.background_color};`"
  >
    <div class="inner-container">
      <div class="quote" :class="`${textUid}`">
        <div v-if="data.main_quote" v-html="data.main_quote"></div>
      </div>
      <div class="quote-author ">
        <p v-if="data.quote_author" v-html="data.quote_author" />
      </div>
      <div class="featured-project" :class="!data.main_quote || data.main_quote === '' ? '!mt-0' : ''">
        <div class="gallery">
          <template v-for="(image, index) in data.project_media" :key="index">

            <img v-if="image.type== 'image'" :class="`img-${index}`" :src="image.url" />
            <video v-if="image.type== 'video'" :class="`img-${index}`" :src="image.url" playsinline muted autoplay loop></video>
          </template>
        </div>
        <div class="content">
          <p
            class="mini-headline"
            v-if="data.project_content.mini_headline"
            :style="data.project_content.sub_color ? `color: ${data.project_content.sub_color};` : ''"
          >{{ data.project_content.mini_headline }}</p>
          <h2
            class="headline"
            :style="data.project_content.text_color ? `color: ${data.project_content.text_color};` : ''"
            v-if="data.project_content.headline"
          >{{ data.project_content.headline }}</h2>
          <p
            class="text-content"
            :style="data.project_content.text_color ? `color: ${data.project_content.text_color};` : ''"
            v-if="data.project_content.content"
            v-html="data.project_content.content"
          />
          <MainButton
            :text="data.project_content.button.title"
            :url="data.project_content.button.url"
            :target="data.project_content.is_download_link ? '_blank' : data.project_content.button.target"
            :color="data.project_content.button_color ? data.project_content.button_color : 'white'"
            :isdownload="data.project_content.is_download_link"
            fileName="media kit"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import MainButton from '@/components/utility/Button.vue'
export default {
  data: function () {
    return {
      uid: null,
      textUid: null
    }
  },
  components: {
    MainButton
  },
  methods: {
    uidCreate () {
      this.uid = `element-${Math.floor(Math.random() * (564 * 100))}`
    },
    textUidCreate () {
      this.textUid = `title-${Math.floor(Math.random() * (564 * 100))}`
    }
  },
  created () {
    this.uidCreate()
    this.textUidCreate()
  },
  props: ['data'],
  mounted () {
    if (this.data.allow_animation === true) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(
      `.${this.uid} .quote`,
      { x: -10, autoAlpha: 0 },
      {
        // scrollTrigger: {
        //   trigger: `.featured-project.${this.uid}`,
        //   scroller: '[data-scroll-container]',
        //   start: 'top-=30% center',
        //   end: 'bottom center'
        //   //
        // },
        x: 0,
        delay: 0,
        duration: 1,
        autoAlpha: 1
      }
      )
      gsap.fromTo(
      `.${this.uid} .quote-author`,
      { y: -5, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: `.featured-project.${this.uid}`,
          scroller: '[data-scroll-container]',
          start: 'top center',
          end: 'bottom center'
        },
        y: 0,
        delay: 0.3,
        duration: 0.3,
        autoAlpha: 1
      }
      )
      gsap.fromTo(
      `.${this.uid} .img-0`,
      { y: 10, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: `.featured-project.${this.uid}`,
          scroller: '[data-scroll-container]',
          start: 'top center',
          end: 'bottom center'
        },
        y: 0,
        delay: 0.6,
        duration: 0.3,
        autoAlpha: 1
      }
      )
      gsap.fromTo(
      `.${this.uid} .img-1`,
      { y: 10, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: `.featured-project.${this.uid}`,
          scroller: '[data-scroll-container]',
          start: 'top center',
          end: 'bottom center'
        },
        y: 0,
        delay: 0.9,
        duration: 0.3,
        autoAlpha: 1
      }
      )
      gsap.fromTo(
      `.${this.uid} .content`,
      { x: 20, autoAlpha: 0 },
      {
        scrollTrigger: {
          trigger: `.featured-project.${this.uid}`,
          scroller: '[data-scroll-container]',
          start: 'top center',
          end: 'bottom center'
        },
        x: 0,
        delay: 1.2,
        duration: 0.3,
        autoAlpha: 1
      }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  @apply py-20;

  .inner-container {
    @apply w-full max-w-screen-xl mx-auto px-6 xl:px-0;
    .quote:deep() {
      @apply w-full lg:w-[75%] mb-10;
      p,
      span {
        @apply text-white text-[34px] lg:text-[60px];
      }
    }
    .quote-author {
      p {
        @apply text-right w-full lg:w-[75%] text-white pr-12;
      }
    }
    .featured-project {
      @apply flex flex-col lg:flex-row mt-4 lg:mt-15 gap-15;
      .gallery {
        @apply flex -ml-6 md:ml-0 flex-row flex-row w-100vw md:w-full lg:w-[70%]
        gap-4 overflow-x-auto md:overflow-x-hidden pb-5
        snap snap-x snap-mandatory ;
        .img-0 {
          @apply w-[90%] lg:w-[70%] object-cover h-[390px] snap-center;
        }
        .img-1 {
          @apply w-full lg:w-[30%] object-cover h-[390px] snap-center;
        }
      }
      .content {
        @apply w-full lg:w-[30%];
        .mini-headline {
          @apply text-white font-bold mb-2;
        }
        .headline {
          @apply text-white text-[36px] mb-6;
        }
        .text-content {
          @apply text-white leading-relaxed mr-0 lg:mr-13;
        }
      }
    }
  }
}
</style>

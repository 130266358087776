<template>
  <main>
    <section class="news">
      <div class="container">
        <div class="posts-header page-header">
          <h1 class="h2">News</h1>
        </div>
        <transition name="fade" mode="out-in">
          <div class="posts-container" v-if="filteredPosts">
            <post-item
              v-for="post in filteredPosts"
              :key="post.id"
              :post="post"
              :showImage="false"
            />
          </div>
        </transition>
      </div>
    </section>
  </main>
</template>

<script>
import PostItem from "@/components/template-parts/PostItem.vue";
import Pagination from "@/components/template-parts/Pagination.vue";

export default {
  name: "Posts",
  components: {
    PostItem,
    Pagination,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      request: {
        type: "posts",
        params: {
          fields:
            "id,slug,title,featured_media,link,categories,excerpt,better_featured_image,date,modified,content",
        },
        showLoading: true,
      },
      pageRequest: {
        type: "pages",
        slug: this.slug,
        showLoading: false,
      },
      categoryRequest: {
        type: "categories",
        params: {
          orderby: "id",
        },
      },
      activeFilter: null,
      filteredPosts: "",
      featuredPosts: [],
      totalPages: 0,
      filterExpanded: false,
      filterOption: "",
    };
  },
  computed: {
    posts() {
      return this.$store.getters.requestedItems(this.request);
    },
    categories() {
      return this.$store.getters.requestedItems(this.categoryRequest);
    },
    postsPage() {
      return this.$store.getters.singleBySlug(this.pageRequest);
    },
  },
  methods: {
    getPosts() {
      return this.$store.dispatch("getItems", this.request);
    },
    getCategories() {
      return this.$store.dispatch("getItems", this.categoryRequest);
    },
    getPostsPageInfo() {
      this.$store.dispatch("getSingleBySlug", this.pageRequest).then(() => {
        if (this.postsPage.acf.featuredPosts) {
          this.postsPage.acf.featuredPosts.forEach((post) => {
            return this.$store
              .dispatch("getSingleById", {
                type: "posts",
                id: post,
                batch: true,
              })
              .then(() => {
                this.featuredPosts.push(
                  this.$store.getters.singleById({
                    type: "posts",
                    id: post,
                    batch: true,
                  })
                );
              });
          });
        }
      });
    },
    setTotalPages() {
      this.totalPages = this.$store.getters.totalPages(this.request);
    },
    handleFilter(index, category = null, name = "") {
      if (index >= 0) {
        this.activeFilter = index;
        this.$refs.filterAll.classList.remove("active");
        const filterRequest = {
          type: "posts",
          params: {
            categories: category,
            fields:
              "id,slug,title,featured_media,link,categories,excerpt,better_featured_image,date,modified,content",
          },
          showLoading: false,
        };
        this.$store.dispatch("getItems", filterRequest).then((res) => {
          this.filteredPosts =
            this.$store.getters.requestedItems(filterRequest);
        });
        this.filterExpanded = false;
        this.filterOption = name;
      } else {
        this.activeFilter = null;
        this.$refs.filterAll.classList.add("active");
        this.filteredPosts = this.posts;
        this.filterExpanded = false;
        this.filterOption = "";
      }
    },
    loadMorePosts() {
      this.request.params.page++;
      return this.$store.dispatch("getItems", this.request).then(() => {
        this.filteredPosts = this.filteredPosts.concat(
          this.$store.getters.requestedItems(this.request)
        );
      });
    },
    openFilter() {
      this.filterExpanded = !this.filterExpanded;
    },
  },
  created() {
    this.getPostsPageInfo();

    this.getPosts().then(() => {
      this.filteredPosts = this.posts;
    });

    this.getCategories();

    this.$store.dispatch("updateDocTitle", {
      parts: ["News", this.$store.state.site.name],
    });
  },
};
</script>

<style lang="scss">
</style>
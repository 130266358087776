<template>
  <section
    class="logos-gallery"
    :style="`background-color: ${props.data.background_color};`"
  >
    <div class="logos-gallery-inner-container">
      <h3
        v-if="props.data.logos_gallery_title"
        :style="`color: ${props.data.title_color};`"
      >
        {{ props.data.logos_gallery_title }}
      </h3>

      <div class="logos-container">
        <div
          class="logo"
          v-for="(logos, index) in props.data.logos"
          :key="index"
          :style="`background-color: ${props.data.logos_background_color};`"
        >
          <template v-if="logos.logo_url">
            <a
              :href="logos.logo_url_link"
              :title="logos.logo.title"
              target="_blank"
            >
              <img
                :src="logos.logo.url"
                :alt="logos.logo.title ? logos.logo.title : 'Client logo'"
            /></a>
          </template>
          <template v-else>
            <img
              :src="logos.logo.url"
              :alt="logos.logo.title ? logos.logo.title : 'Client logo'"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const props = defineProps(['data'])

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)
  gsap.fromTo(
    ['.logos-gallery h3', '.logos-container .logo'],
    { autoAlpha: 0 },
    {
      scrollTrigger: {
        scroller: '[data-scroll-container]',
        trigger: '.logos-gallery-inner-container',
        start: '-100px center'
      },
      autoAlpha: 1,
      stagger: 0.1
    }
  )
})
</script>

<style lang="scss" scoped>
.logos-gallery {
  @apply bg-text py-14;
  .logos-gallery-inner-container {
    @apply w-full max-w-screen-xl mx-auto px-4;

    .logos-container {
      @apply pt-14 flex flex-wrap gap-[16px];

      .logo {
        @apply bg-secondaryGray relative py-2 px-2 w-[calc(50%_-_8px)] sm:w-[calc(25%_-_12px)];

        img {
          @apply w-full;
        }

        a {
          @apply hover:cursor-pointer;
        }
      }
    }
  }
}
</style>

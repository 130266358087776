<template>
  <section class="textList">
  <div class="inner-container">
    <div class="leftSide">
      <h2>{{ data.title }}</h2>
    </div>
    <div class="rightSide">

      <p v-html="data.description"></p>

    </div>
    <div class="list-container">
      <ul class="list">
        <li v-for="(item, index) in data.list" :key="index" class="list-item">
          <div><img :src="check" alt="Check"></div>
          <p>{{item.item}}</p>
        </li>
      </ul>
    </div>
  </div>
  </section>
</template>

<script>
import check from '@/assets/img/check_white.svg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
  name: 'TextList',
  props: ['data'],
  data () {
    return {
      check
    }
  },
  mounted () {
    // console.log(this.data);
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      ['.rightSide > p'],
      { y: 100, autoAlpha: 0 },
      {
        y: 0,
        autoAlpha: 1,
        stagger: 0.1,
        scrollTrigger: {
          trigger: '.textList',
          start: '-20px center',
          scroller: '[data-scroll-container]',
          end: 'center center'
        }
      }
    )
    const elem = document.querySelector('.rightSide > ul li')
    if (elem) {
      gsap.fromTo(
        '.rightSide > ul li',
        { x: 100, autoAlpha: 0 },
        {
          x: 0,
          delay: 0.5,
          autoAlpha: 1,
          stagger: 0.1,
          scrollTrigger: {
            trigger: '.list',
            start: '-100px center',
            scroller: '[data-scroll-container]',
            end: 'center center'

          }
        }
      )
    }

    ScrollTrigger.refresh()
  }
}
</script>

<style lang="scss" scoped>
.textList {
  @apply px-6;
  .inner-container {
     @apply w-full max-w-screen-xl mx-auto py-16 flex flex-col lg:flex-row flex-wrap justify-between;

     .leftSide{
       @apply w-full lg:w-5/12 mb-10 text-5xl;
       h2{
        @apply text-5xl font-medium ;
       }
     }
     .rightSide{
       @apply w-full lg:w-6/12 mr-12 flex flex-row justify-end;

        p{
          @apply lg:w-[95%] ;
        }

     }
     .list-container{
      @apply w-full flex flex-row justify-end mt-10;
      ul.list{
          @apply w-full xl:w-[880px]  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-4;
          .list-item{
            @apply flex flex-row items-center border-2 border-accentGreen  w-full h-20 pr-3 relative;
            div{
              @apply w-6 h-6 flex items-center justify-center absolute left-0 top-0 p-1 bg-accentGreen;

            }
            p{
              @apply ml-12 my-0 text-text text-lg font-light;
            }
          }
        }
     }
  }
}
</style>

<template>
  <li
    class="menu-items group relative items-center"
    :class="`${hasSub(item)} ${initialMobile || mobile ? 'mobile-subnav-root' : ''}`"
    v-if="item.parent === '0'"
  >
    <template v-if="item.class.includes('has-subnav')">
      <template v-if="item.url !== '#'">
        <template v-if="initialMobile || mobile">
          <div class="z-2 w-max">
            <router-link
              class="mobile-subnav-parent lg:mb-0"
              :class="subnavOpen ? 'mb-3' : ''"
              :to="$root.convertToRelative(item.url)"
            >{{ item.content }}</router-link>
          </div>
          <div class="submenu-trigger z-1 absolute h-[37px] w-full" @click="openSubnav">
            <img
              class="caret ml-2 absolute top-[12px] right-[25px]"
              :class="subnavOpen ? 'transform rotate-180' : ''"
              src="@/assets/img/caret-down.svg"
            />
          </div>
        </template>
        <template v-else>
          <router-link
            class="desktop-subnav-parent mb-3 lg:mb-0"
            :to="$root.convertToRelative(item.url)"
          >
            {{ item.content }}
            <img
              class="caret ml-2"
              :class="sticky ? 'sticky' : ''"
              src="@/assets/img/caret-down.svg"
            />
          </router-link>
        </template>
      </template>
      <template v-else>
        <div class="desktop-subnav-parent mb-0 lg:mb-3 lg:mb-0">
          {{ item.content }}
          <div
            class="ml-2 inline-block transform group-hover:rotate-180 transition transition-transform ease-in-out flex items-center chevron"
            :class="{ 'rotate-180': subnavOpen }"
          >
            <svg width="13px" height="6px" viewBox="0 0 13 6" version="1.1" stroke="inherit">
              <g
                stroke="inherit"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-dasharray="0 0"
              >
                <g transform="translate(-1074.000000, -93.000000)" stroke="inherit">
                  <g transform="translate(711.000000, 86.000000)">
                    <g transform="translate(304.000000, 0.000000)">
                      <path d="M60,7 L65.5,13 L71,7" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <template v-if="this.$root.isMobile || this.$root.isTablet">
        <slide-up-down v-model="subnavOpen" class="subnav-accordion">
          <ul class="sub-menu">
            <template v-for="sub in menu">
              <li v-if="sub.parent == item.id" :key="sub.id" :title="sub.title">
                <template v-if="$root.isExternal(sub.url)">
                  <a
                    :href="sub.url"
                    :target="sub.target"
                    :title="sub.content"
                    @click="closeEverything"
                  >{{ sub.content }}</a>
                </template>
                <template v-else>
                  <router-link
                    @click.native="closeEverything"
                    :to="$root.convertToRelative(sub.url)"
                    v-html="sub.content"
                  ></router-link>
                </template>
              </li>
            </template>
          </ul>
        </slide-up-down>
      </template>
      <template v-else>
        <ul class="sub-menu">
          <template v-for="sub in menu">
            <li
              v-if="sub.parent == item.id"
              :key="sub.id"
              :title="sub.title"
              :class="arrayToString(sub.class)"
            >
              <template v-if="$root.isExternal(sub.url)">
                <a
                  :href="sub.url"
                  :class="arrayToString(sub.class)"
                  :target="sub.target"
                  :title="sub.content"
                >{{ sub.content }}</a>
              </template>
              <template v-else>
                <router-link
                  @click.native="closeEverything"
                  :to="$root.convertToRelative(sub.url)"
                  v-html="sub.content"
                ></router-link>
              </template>
            </li>
            <template v-else></template>
          </template>
          <div class="triangle"></div>
        </ul>
      </template>
    </template>
    <template v-else-if="item.class.includes('CTA') && !(initialMobile || mobile)">
      <router-link @click.native="closeEverything" :to="$root.convertToRelative(item.url)" exact>
        {{ item.content }}
        <svg
          class="ml-2"
          width="17"
          height="8"
          viewBox="0 0 17 8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.844 4.18558C17.0393 3.99032 17.0393 3.67374 16.844 3.47848L13.6621 0.296497C13.4668 0.101235 13.1502 0.101235 12.955 0.296497C12.7597 0.491759 12.7597 0.808342 12.955 1.0036L15.7834 3.83203L12.955 6.66046C12.7597 6.85572 12.7597 7.1723 12.955 7.36757C13.1502 7.56283 13.4668 7.56283 13.6621 7.36757L16.844 4.18558ZM0.925781 4.33203H16.4905V3.33203H0.925781V4.33203Z"
          />
        </svg>
      </router-link>
    </template>
    <template v-else>
      <router-link
        @click.native="closeEverything"
        :to="$root.convertToRelative(item.url)"
        exact
        v-html="item.content"
      ></router-link>
    </template>
  </li>
</template>

<script>
import SlideUpDown from 'vue3-slide-up-down'

export default {
  name: 'NavMenu',
  data() {
    return {
      subnavOpen: false,
      sticky: false,
      initialMobile: false
    }
  },
  computed: {
  },
  components: {
    SlideUpDown
  },
  props: [
    'menu',
    'item',
    'mobile',
  ],
  methods: {
    hasSub(item) {
      return item.class.includes('has-subnav') ? `parent-nav ${this.arrayToString(item.class)}` : `${this.arrayToString(item.class)}`;
    },
    openSubnav() {
      this.subnavOpen = !this.subnavOpen
    },
    closeEverything() {
      this.$emit('clicked', false)
    },
    arrayToString(array) {
      return array.join(' ')
    }
  },
  mounted() {
    this.initialMobile = this.$root.isMobile
    document.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        this.sticky = true
      } else {
        this.sticky = false
      }
    });
  },
  unmounted() {
    document.removeEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.sticky = true
      } else {
        this.sticky = false
      }
    });
  },
}
</script>

<style lang="scss">
#header-nav.black {
  .desktop-subnav-parent .chevron {
    @apply transition transition-all delay-500 duration-500 ease-in-out;
    stroke: black;
  }
  .CTA {
    @apply transition transition-all delay-500 duration-500 ease-in-out;
    fill: black;
  }
  a.router-link-active {
    @apply border-black;
  }
  .caret {
    @apply transition transition-all duration-500 filter lg:brightness-0;
  }
  .caret {
    @apply transition transition-all duration-500 filter lg:brightness-100;
    &.sticky {
      @apply transition transition-all delay-500 duration-500 filter lg:brightness-0;
    }
  }
}

.caret {
  @apply transition transition-all duration-500 filter lg:brightness-100;
}

#header-nav.white {
  .desktop-subnav-parent .chevron {
    stroke: white;
  }
  .CTA {
    @apply transition transition-all delay-500 duration-500 ease-in-out;
    fill: white;
  }
  .caret {
    @apply transition transition-all duration-500 filter lg:brightness-100;
  }
  .caret {
    @apply transition transition-all duration-500 filter lg:brightness-100;
    &.sticky {
      @apply transition transition-all delay-1000 duration-500 filter lg:brightness-0;
    }
  }
}

.menu-items {
&.orange {
  a {
    @apply !text-accentOrange;
  }
}
  &.has-subnav {
    @apply !flex;
    a {
      @apply w-max;
    }
  }
  @apply flex flex-col py-3 lg:py-0 md:pb-5 relative fill-white stroke-white;
  .sub-menu {
    li {
      @apply p-0;
      a {
        @apply lg:p-10 lg:pr-18 w-full inline-block;
      }
    }
  }
  a {
    @apply text-white self-center lg:self-start 
    opacity-70 hover:opacity-100 hover:text-accentGreen 
    w-full h-full transition-opacity duration-200 
    font-neueHaasUnica transition transition-all duration-500 ease-in-out;
  }
  a.router-link-active {
    @apply pb-1 opacity-100 text-accentGreen;
  }
  .subnav-accordion {
    @apply w-full;
  }
}

.desktop-subnav-parent {
  @apply flex flex-row justify-center opacity-60 hover:opacity-100 transition-opacity duration-200;
  stroke: inherit;
  .chevron {
    stroke: inherit;
    svg {
      stroke: inherit;
    }
  }
}

.mobile-subnav-parent {
  @apply inline-block opacity-60 hover:opacity-100 transition-opacity duration-200;
  stroke: inherit;
}

.mobile-subnav-root {
  img {
    &::before {
      @apply content-[''] absolute w-full h-full bg-green-500;
    }
  }
}

li.CTA {
  @apply lg:p-0;
  a {
    @apply lg:border-1 lg:border-white lg:!p-3 lg:!px-6 lg:flex lg:items-center lg:content-center;
    svg {
      fill: inherit;
      stroke: none;
    }
  }
}

.sub-menu {
  @apply relative lg:absolute flex flex-col top-0 
    w-auto lg:right-[-15px] lg:transform lg:translate-y-[-999999px]
     bg-offwhite md:shadow-xl xl:max-w-[800px] w-full xl:w-max;
  li {
    @apply flex group justify-center xl:justify-start text-center lg:text-left text-black hover:text-white hover:bg-secondary
      lg:border-b-black lg:border-b-solid lg:border-b-1 lg:border-opacity-10 lg:px-2 lg:py-1;
    a {
      @apply flex py-4 lg:last:py-3;
    }
  }
}
@media all and (min-width: 1024px) {
  ul.sub-menu .triangle {
    content: "";
    position: absolute;
    top: -10px;
    right: 12px;
    height: 10px;
    width: 10px;
    background-color: #ffffff00;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e9edee;
    clear: both;
    transform: rotate(180deg);
  }
  .sub-menu > li:nth-child(1):hover ~ .triangle {
    border-top: 10px solid rgba(0, 181, 223, 1);
  }
}

.parent-nav:hover .sub-menu {
  @apply lg:opacity-100 transition-opacity duration-[0.5s] ease-in-out lg:transform lg:translate-y-[56px];
}
</style>
<template >

    <a v-if="url && isdownload" :class="`button ${color}`" :href="url" :target="target" :download="`${fileName}`">
      <div class="button-icon">
        <svg
          v-if="isdownload"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          class="transform rotate-90"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#004023"
          />
        </svg>
        <svg
          v-else
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#004023"
          />
        </svg>
      </div>
      {{ text }}
    </a>
    <a v-else-if="url" :class="`button ${color}`" :href="url" :target="target">
      <div class="button-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#004023"
          />
        </svg>
      </div>
      {{ text }}
    </a>
    <button v-else-if="!url && func" :class="`button ${color}`" @click="func(args)">
      <div class="button-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#004023"
          />
        </svg>
      </div>
      {{ text }}
    </button>
      <div v-else :class="`button cursor-pointer ${color}`">
      <div class="button-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#004023"
          />
        </svg>
      </div>
      {{ text }}
    </div>

</template>

<script>
import arrowSVG from '@/assets/img/arrow.svg'
export default {
  name: 'Button',
  props: {
    text: String,
    url: String,
    target: String,
    color: String,
    func: Function,
    args: Number,
    isdownload: Boolean,
    fileName: String
  },
  mounted () {
    // console.log(this.isdownload, this.url)
  },
  data () {
    return {
      arrow: arrowSVG
    }
  }
}
</script>

<style lang="scss" scoped>
.button.green {
  @apply inline-flex my-5 py-3.5 px-3.5 w-52 border-solid border-y-2 border-primary hover:bg-primary hover:text-white justify-between items-center text-primary;
  @apply transition transition-all ease-in-out duration-200;
  .button-icon {
    @apply transition transition-all ease-in-out duration-200;
    svg {
      path {
        @apply fill-primary;
        @apply transition transition-all ease-in-out duration-200;
      }
    }
  }
  &:hover {
    .button-icon {
      @apply transform rotate-45 translate-x-5;
      @apply transition transition-all ease-in-out duration-200;
      svg {
        path {
          @apply fill-white;
          @apply transition transition-all ease-in-out duration-200;
        }
      }
    }
  }
}

.button.black {
  @apply inline-flex my-5 py-3.5 px-3.5 w-52 border-solid border-y-2 border-text hover:bg-text hover:text-white justify-between items-center text-text;
  @apply transition transition-all ease-in-out duration-200;
  .button-icon {
    @apply transition transition-all ease-in-out duration-200;
    svg {
      path {
        @apply fill-text;
        @apply transition transition-all ease-in-out duration-200;
      }
    }
  }
  &:hover {
    .button-icon {
      @apply transform rotate-45 translate-x-5;
      @apply transition transition-all ease-in-out duration-200;
      svg {
        path {
          @apply fill-white;
          @apply transition transition-all ease-in-out duration-200;
        }
      }
    }
  }
}
.button.white {
  @apply inline-flex my-5 py-3.5 px-3.5 w-52 border-solid border-y-2 border-white hover:bg-white hover:text-white justify-between items-center text-white hover:text-primary;
  @apply transition transition-all ease-in-out duration-200;
  .button-icon {
    @apply transition transition-all ease-in-out duration-200;
    svg {
      path {
        @apply fill-white;
        @apply transition transition-all ease-in-out duration-200;
      }
    }
  }

  &:hover {
    .button-icon {
      @apply transform rotate-45 translate-x-5;
      @apply transition transition-all ease-in-out duration-200;
      svg {
        path {
          @apply fill-primary;
          @apply transition transition-all ease-in-out duration-200;
        }
      }
    }
  }
}
</style>

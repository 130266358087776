import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const { url } = __VUE_WORDPRESS__.routing

// scroll position is handled in @after-leave transition hook
if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { top: 0 }
    }
    // if (savedPosition) {
    //   position.x = savedPosition.x
    //   position.y = savedPosition.y

    //   return new Promise(resolve => {
    //     this.app.$root.$once("scrollBeforeEnter", () => {
    //       resolve(position)
    //     })
    //   })
    // } else {
    //   return new Promise(resolve => {
    //     this.app.$root.$once("scrollBeforeEnter", () => {
    //       resolve(position)
    //     })
    //   })
    // }
  }
})

<template>
  <section v-if="dataLoaded" class="single-project pt-30">
    <div class="single-project-container">
      <!-- title -->
      <div class="title" v-if="project.title.rendered.length > 0">
        <h3 v-html="project.title.rendered"></h3>
      </div>
      <!-- media container -->
      <div class="media-container">
        <video v-if="project.acf.hero_section.show_video" :poster="project.acf.hero_section.project_hero_image.url"
          autoplay muted loop class="media-resource" playsinline>
          <source :src="project.acf.hero_section.video_file.url"
            :type="project.acf.hero_section.video_file.mime_type" />
          Your browser does not support the video tag.
        </video>
        <img v-if="!project.acf.hero_section.show_video"
          v-lazy:background="project.acf.hero_section.project_hero_image.url"
          :alt="project.acf.hero_section.project_hero_image.alt" class="background-image media-resource" />
      </div>
      <!-- content -->
      <div class="content">

        <div class="info-container mb-6">
          <div v-if="project.acf.location && project.acf.location.length > 0" class="item">
            <span class="info-label">Location: </span>
            <strong class="info-label">{{ project.acf.location }}</strong>
          </div>
          <div v-if="project.acf.owner && project.acf.owner.length > 0" class="item">
            <span class="info-label">Owner: </span>
            <strong class="info-label">{{ project.acf.owner }}</strong>
          </div>
          <div v-if="project.acf.award_name && project.acf.award_name.length > 0" class="item">
            <span class="info-label">Awards: </span>
            <strong class="info-label">{{ project.acf.award_name }}</strong>
          </div>
          <div v-if="project.acf.completion_date && project.acf.completion_date.length > 0" class="item">
            <span class="info-label">Completion date: </span>
            <strong class="info-label">{{ project.acf.completion_date }}</strong>
          </div>
          <div v-if="project.acf.architect && project.acf.architect.length > 0" class="item">
            <span class="info-label">Architect: </span>
            <strong class="info-label">{{ project.acf.architect }}</strong>
          </div>
        </div>
        <div class="text-container">
          <div :class="`column-${project.acf.columns}`">
            {{ project.acf.content }}
          </div>
        </div>
        <Facts :facts="{ title: '', facts: project.acf.facts.fact }" :fromProps="true" />
      </div>
      <!-- gallery -->
      <div v-if="project.acf.gallery.gallery_item.length > 0" class="gallery-container">
        <div v-for="(itemRow, indexRow) in gallery" :key="`row-${indexRow}`" class="row-item">
          <div v-if="Array.isArray(itemRow)" class="col-item">
            <div class="image-wrapper item-image-col" v-for="(itemCol, indexCol) in itemRow" :key="`col-${indexCol}`">
              <img :src="itemCol.image.url" :alt="itemCol.image.alt" class="item-image"
                :class="itemCol.caption.length > 0 ? 'animated' : ''" />
              <div v-if="itemCol.caption.length > 0" class="overlay">
                <div class="caption-wrapper">
                  <span class="caption">{{ itemCol.caption }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="image-wrapper">
            <img :src="itemRow.image.url" class="item-image" :alt="itemRow.alt"
              :class="itemRow.caption.length > 0 ? 'animated' : ''" />
            <div class="overlay" v-if="itemRow.caption.length > 0">
              <div class="caption-wrapper">
                <span class="caption">{{ itemRow.caption }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- quote -->
      <Quote v-if="project.acf.quote_area.quote" :fromProps="true"
        :quote="{ content: project.acf.quote_area.quote, author: project.acf.quote_area.author, isDarkMode: true }" />
    </div>
    <!-- Hero -->
    <Hero :data="CTAInfo" />
    <!-- Related Projects -->
    <div class="single-project-container related-projects">
      <RelatedProjects :categories="project.projects_categories" :exclude="project?.id" />
    </div>
  </section>
</template>

<script>
import Facts from '@/components/modules/Facts.vue'
import Quote from '@/components/modules/Quote.vue'
import Hero from '@/components/modules/Hero.vue'
import RelatedProjects from '@/components/RelatedProjects.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  components: {
    Facts,
    Quote,
    Hero,
    RelatedProjects
  },
  props: ['slug'],
  data () {
    return {
      animationAdded: false,
      dataLoaded: false,
      relatedProjects: [],
      project: {},
      request: {
        type: 'projects',
        slug: this.slug,
        showLoading: true
      }
    }
  },
  computed: {
    CTAInfo () {
      const initialData = this.$store.state.acf_options.single_project_cta
      const imageUrl = initialData.cta_bg_image.sizes?.['1536x1536'] ? initialData.cta_bg_image.sizes['1536x1536'] : initialData.cta_bg_image.url
      return {
        acf_fc_layout: 'heroSection',
        content_section: {
          content: initialData.content,
          button: { title: initialData.button.title, url: initialData.button.url, target: initialData.button.target },
          box_position: 'Left'
        },
        image_section: {
          image: {url: imageUrl},
          title: initialData.title,
          sub_title: initialData.top_text
        }
      }
    },
    gallery () {
      const arrayLength = this.project.acf.gallery.gallery_item.length - 1
      const gallery = []
      let auxArray = []
      let auxCont = 0

      this.project.acf.gallery.gallery_item.forEach((element, index) => {
        if (auxCont === 0) {
          if (index + 1 <= arrayLength) {
            auxArray = []
            auxArray.push(element)
          } else {
            gallery.push(element)
          }
        }

        if (auxCont === 1) {
          auxArray.push(element)
          gallery.push(auxArray)
        }

        if (auxCont === 2) {
          gallery.push(element)
        }

        auxCont++
        if (auxCont === 3) {
          auxCont = 0
        }
      })

      return gallery
    }
  },
  methods: {

    getProject () {
      this.$store
        .dispatch('getSingleBySlug', this.request)
        .then(() => {
          // console.log('this.project;', this.project);
          this.updateProject()
          this.updateSiteTitle()
        })
    },
    updateProject () {
      this.project = this.$store.getters.singleBySlug(this.request)
      this.dataLoaded = true
    },
    updateSiteTitle () {
      this.$store
        .dispatch('updateDocTitle', {
          parts: [
            this.$root.decodeTitle(this.project.title.rendered),
            this.$store.state.site.name
          ]
        })
    },
    animatedSingleProject () {
      gsap.registerPlugin(ScrollTrigger)
      // title
      gsap.fromTo(
        '.single-project .title',
        { y: 20, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: '.single-project',
            scroller: '[data-scroll-container]',
            start: 'top center',
            end: 'bottom center'
          },
          y: 0,
          delay: 0.5,
          duration: 1,
          autoAlpha: 1
        }
      )
      // Media Resources
      gsap.fromTo(
        '.media-container .media-resource',
        { y: 40, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: '.media-container',
            scroller: '[data-scroll-container]',
            start: 'top center',
            end: 'bottom center'
          },
          y: 0,
          duration: 0.5,
          autoAlpha: 1
        }
      )
      // Content Info
      // gsap.fromTo(
      //   `.content .info-container .item`,
      //   {x: 30, autoAlpha: 0},
      //   {
      //     scrollTrigger: {
      //       trigger: `.content`,
      //       scroller: "[data-scroll-container]",
      //       start: "top center",
      //       end: "bottom center",
      //     },
      //     x: 0,
      //     duration: 1,
      //     autoAlpha: 1,
      //     stagger: 0.5
      //   }
      // );
      // Content Text
      // gsap.fromTo(
      //   `.content .text-container .column`,
      //   {x: -20, autoAlpha: 0},
      //   {
      //     scrollTrigger: {
      //       trigger: `.content`,
      //       scroller: "[data-scroll-container]",
      //       start: "top center",
      //       end: "bottom center",
      //     },
      //     x: 0,
      //     duration: 0.5,
      //     autoAlpha: 1,
      //     stagger: 0.5,
      //     delay: 0.25
      //   }
      // );
      // Content Gallery
      gsap.fromTo(
        '.gallery-container .item-image',
        { x: -20, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: '.gallery-container',
            scroller: '[data-scroll-container]',
            start: 'top center',
            end: 'bottom center'
          },
          x: 0,
          duration: 0.5,
          autoAlpha: 1,
          stagger: 0.5
        }
      )
    }
  },
  created () {
    this.getProject()
  },
  mounted () {
    // console.log('data', this.CTAInfo)
  },
  updated () {
    if (!this.animationAdded) {
      this.animatedSingleProject()
      this.animationAdded = true
    }
  }
}
</script>
<style lang="scss" scoped>
.single-project {
  @apply w-full mb-4;

  .single-project-container {
    @apply w-full max-w-screen-xl mx-auto py-12 font-neueHaasUnica font-light px-6 xl: px-0;

    .atropos {
      @apply pt-6;
    }

    .related-projects {
      @apply py-8;
    }

    .title {
      @apply pb-4;
    }

    .media-container {
      @apply w-full py-4 overflow-hidden max-h-[500px] mb-8;

      .background-image {
        @apply  w-full h-screen max-h-[25vh] lg:max-h-[600px] bg-cover bg-no-repeat object-cover bg-center;
      }

      video {
        @apply block mx-auto w-full h-full;
      }
    }

    .content {
      @apply flex flex-col space-y-4 mb-6;

      .atropos {
        @apply pt-2;
      }

      .info-container {
        @apply flex flex-col space-y-4;

        .item {
          @apply text-[20px] leading-6;
        }
      }

      .text-container {
        @apply flex flex-col lg: flex-row space-y-4 md:space-x-4 xl:space-x-8 lg:space-y-0 text-[18px] leading-snug ;

        .column-1{
          columns: 50vh 1;
          text-align: justify
        }
        .column-2 {
          columns: 50vh 2;
          text-align: justify
        }
      }
    }

    .gallery-container,
    .row-item {
      @apply flex flex-col space-y-2;

      .image-wrapper {
        @apply relative overflow-hidden;

        .item-image {
          @apply w-full h-[25vh] md: h-[45vh] lg:h-[55vh] bg-no-repeat bg-cover bg-center object-cover transition-all ease-in-out duration-500;
        }

        &:hover {
          .animated {
            transform: scale(1.1) !important;
          }

          .overlay {
            @apply absolute;

            &:before {
              @apply opacity-50 transition-opacity duration-1000 ease-in-out;
            }

            .caption-wrapper {
              .caption {
                @apply opacity-100;
              }
            }
          }
        }

        .overlay {
          @apply absolute p-4 md: p-8 w-full h-full top-0 left-0;

          &:before {
            @apply absolute content-[''] w-full h-full left-0 top-0 bg-black opacity-0 transition-opacity duration-1000 ease-in-out;
          }

          .caption-wrapper {
            @apply w-auto md: w-1/2 lg:w-2/5 absolute bottom-[2rem];

            .caption {
              @apply font-neueHaasUnica font-medium text-base leading-6 text-white transition-all ease-in-out duration-1000 opacity-0;
            }
          }
        }
      }

      .item-image-col {
        @apply w-full md: w-1/2 h-[25vh] md:h-[45vh] lg: h-[55vh] bg-cover bg-no-repeat object-cover bg-center;
      }

      .col-item {
        @apply flex flex-col space-y-2 md: flex-row md:space-y-0 md:space-x-2;
      }
    }
  }
}
</style>

<template>
  <section class="bannerCta" :style="`background-color:${data.colors.background_color}`">
    <div class="inner-container">
      <div>
        <p :style="`color:${data.titles.color_headline}`">{{ data.titles.headline }}</p>
        <h2 :style="`color:${data.titles.color_title}`">{{ data.titles.title }}</h2>

      </div>
      <p :style="`color:${data.colors.text_color}`" v-html="data.content.text" />
      <Button class="bannerCta__button" :text="data.content.link.title" :url="data.content.link.url"
        :target="data.content.link.target" :color="data.content.button_style"></Button>
    </div>
  </section>
</template>

<script>
import Button from "@/components/utility/Button.vue";
export default {
  props: ["data"],

  components: {
    Button,

  },

};
</script>

<style lang="scss" scoped>
.bannerCta {
  @apply py-10 px-3 xl: px-0;

  .inner-container {
    @apply w-full max-w-screen-xl mx-auto flex flex-col lg: flex-row justify-between content-start lg:content-center items-start lg:items-center;

    h2 {
      @apply text-3xl flex flex-row content-center mb-5 lg: mb-0;
    }

    p {
      @apply text-base w-full lg: w-1/2 mb-5 lg:mb-0;
    }

    .bannerCta__button {
      @apply;
    }
  }

}
</style>

<template>
  <section class="media-projects-section">
    <div class="media-projects-inner-section">
      <h4>{{data.section_title}}</h4>
      <div class="media-projects-gallery mt-8 flex flex-wrap gap-4">
          <project-card v-for="(project,index) of data.cards"
          :key="index"
          width="half"
          :title="project.title"
          :subTitle="project.sub_title"
          :image="project.background_image.url"
          :description="project.description"
          :link="project.button.url"
          :buttonText="project.button.title"
          :download="true"
          :isProject="false"
          :index="index"
          :isMediaGallery="false"
          />

      </div>
    </div>
  </section>
</template>

<script setup>
import ProjectCard from './Project-card.vue'
import { onMounted } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

defineProps(['data'])

onMounted(() => {
  // console.log(props.data)
  animateComponents()
})

const animateComponents = () => {
  gsap.registerPlugin(ScrollTrigger)
  gsap.set('.project-card', { autoAlpha: 0, y: 20 })
  ScrollTrigger.batch('.project-card', {
    scroller: '[data-scroll-container]',
    onEnter: batch => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.3 }),
    start: '20% bottom',
    end: 'top bottom',
    markers: false
  })
}

</script>

<style lang="scss" scoped>
  .media-projects-section:deep() {
    @apply relative pt-10 pb-20;
    .media-projects-inner-section {
      @apply w-full max-w-screen-xl mx-auto px-6 xl:px-0 py-10 pt-10;
    }
  }
</style>

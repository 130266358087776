<template>
  <div class="post-meta">
    <div v-if="post.categories.length" class="categories">
      <archive-link
        v-for="category in post.categories"
        :key="category"
        archive-type="categories"
        :archive-id="category"
        class="post-category"
      />
    </div>
  </div>
</template>

<script>
import ArchiveLink from '@/components/utility/ArchiveLink.vue'

export default {
  name: 'PostTaxonomies',
  components: { ArchiveLink },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">

</style>

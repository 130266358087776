<template>
  <section class="vertical-scrolling">
    <div class="inner-container" id="scrolling-container">
      <div class="tabs-navigation" id="tabs-navigation">
        <div
          class="link-container"
          v-for="(nav, index) in tabsList"
          :key="index"
        >
          <a
            :id="`tabs-nav-${index}`"
            target="_self"
            :title="nav"
            @click="tabScroll(index)"
            >{{ nav }}</a
          >
        </div>
      </div>
      <div class="tabs-container" id="tabs-container">
        <div
          class="tabs-content"
          :id="`tabs-content-${index}`"
          v-for="(content, index) in data.values"
          :key="index"
        >
          <h4 v-if="content.content.length > 0">{{ content.title }}</h4>
          <template v-for="(info, idx) in content.content">
            <div v-if="info.content_type === 'text'" :key="idx" class="text" v-html="info.inner_text" />
            <div
              :key="`gall-${idx}`"
              v-if="info.content_type === 'gallery'"
              class="gallery"
              :class="`gallery__${info.gallery.length}`"
            >
              <template v-for="(image, index) in info.gallery" :key="`imag-${index}`">
                <img
                  :class="`tabs-img-${index} ${!info.image_responsive ? ' h-[440px]' : 'h-full lg:h-[440px]'}`"
                  :src="image.url"
                  :title="image.title ? image.title : 'Image'"
                />
              </template>
            </div>
            <div v-if="info.content_type === 'full_image'" :key="`img-${idx}`">
              <img class="mt-6 w-full h-auto" :src="info.full_image.url" alt="" />
            </div>
            <div v-if="info.content_type === 'video'" :key="`img-${idx}`">

              <video class="mt-6 w-full h-auto" :src="info.video.url" controls :poster="info.image_thumbnail?.url ? info.image_thumbnail.url : defaultImage" playsinline />
            </div>
            <div v-if="info.content_type === 'YTvideo'" :key="`img-${idx}`">
              <iframe class="mt-6 w-full h-auto min-h-sm md:min-h-lg" :src="`https://www.youtube.com/embed/${info.YTvideo}?modestbranding=&playsinline=1`" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
            </div>
            <div v-if="info.content_type === 'team_members'" :key="`team-${idx}`">
              <h4 class="text-white pt-14 pb-4">{{info.team_members.title}}</h4>
              <div class="team-members">
                <div class="member" v-for="(member, ind) of info.team_members.members" :key="`sngl-team-${ind}`">
                  <div class='member-pic'>
                  <img :src="member.image.url" />
                    <div class="badge">
                      <a v-if="member.linkedin" :href="member.linkedin" target="_blank">
                       <img src="@/assets/img/linkedin-member.svg" />
                      </a>
                    </div>
                  </div>
                  <p class="member-name">
                    {{member.name}}
                  </p>
                  <div class="member-title" v-html="member.title"/>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// imports
import { gsap } from 'gsap'
import defaultImage from '@/assets/img/default-video.jpg'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  props: ['data'],
  data: function () {
    return {
      tabsList: [],
      scrollTriggerData: [],
      defaultImage: defaultImage
    }
  },
  methods: {
    loadTabs () {
      for (const property of this.data.values) {
        this.tabsList.push(property.title)
      }
    },
    setActiveTab () {
      const targets = gsap.utils.toArray('.tabs-content')
      gsap.registerPlugin(ScrollTrigger)

      ScrollTrigger.saveStyles('.tabs-navigation')

      for (let i = 0; i < targets.length; i++) {
        this.scrollTriggerData.push(`tabs-trigger-${i}`)
        gsap.to(`#tabs-content-${i}`, {
          scrollTrigger: {
            id: `tabs-trigger-${i}`,
            trigger: `#tabs-content-${i}`,
            scroller: '[data-scroll-container]',
            start: 'top 100px',
            end: 'bottom 100px',
            scrub: true,
            toggleClass: { targets: `#tabs-nav-${i}`, className: 'active' }
          }
        })
      }

      ScrollTrigger.matchMedia({
        '(min-width: 1024px)': function () {
          gsap.to('#tabs-navigation', {
            scrollTrigger: {
              id: 'tabs-trigger-text',
              trigger: '#tabs-navigation',
              scroller: '[data-scroll-container]',
              start: 'top top',
              endTrigger: '#scrolling-container',
              end: 'bottom-=100px center',
              scrub: true,
              pin: true
            }
          })
        }
      })
    },
    tabScroll (index) {
      const target = document.getElementById(`tabs-content-${index}`)
      if (this.tabsList.length === index + 1) {
        this.$root.locoScroll.scrollTo(target, { offset: 150 })
      } else {
        this.$root.locoScroll.scrollTo(target)
      }
    }
  },
  mounted () {
    this.loadTabs()
    this.setActiveTab()
    ScrollTrigger.refresh()
  },
  updated () {
    ScrollTrigger.refresh()
  },
  beforeUnmount () {
    ScrollTrigger.getAll().forEach((trigger) => {
      trigger.kill()
    })
    ScrollTrigger.clearMatchMedia()
  }
}
</script>

<style lang="scss" scoped>
.vertical-scrolling {
  @apply bg-primary py-20;
  .inner-container {
    @apply w-full max-w-screen-xl mx-auto px-4 lg:flex justify-between;

    .tabs-navigation {
      @apply flex flex-col pt-16 lg:w-[30%] lg:pr-6rem lg:inline-block lg:h-max;

      .link-container {
        @apply border-b-2 border-accentGreen last:border-none lg:py-8;

        a {
          @apply text-white py-8 text-[16px] block transition duration-300 ease-in hover:cursor-pointer hover:text-accentOrange lg:inline-block lg:py-0;
          &.active {
            @apply text-accentOrange;
          }
        }
      }
    }

    .tabs-container {
      @apply w-full lg:w-[70%];
      .tabs-content {
        @apply pt-16 pb-14 border-b-2 border-white last:border-none;

video{
 overflow: hidden;
 position: relative;
}

        video[poster] {
          height: 100%;
          width: 100%;
        }

        h4 {
          @apply text-white mb-8;
        }
        .text:deep() {
          p {
            @apply text-white mb-8;
          }
        }

        .gallery {
          @apply flex flex-col lg:flex-row flex-wrap w-full gap-4 mb-8;

          img {
            @apply object-cover ;

          }
          .tabs-img-0 {
            @apply w-full lg:object-cover ;
          }
          .tabs-img-1 {
            @apply w-full object-cover;
          }
          .tabs-img-2 {
            @apply w-full object-cover;
          }

          &.gallery__2:deep() {
            .tabs-img-0 {
              @apply lg:w-[calc(50%_-_1rem)];
            }

            .tabs-img-1 {
              @apply lg:w-[50%];
            }
          }
          &.gallery__3:deep() {
            .tabs-img-1 {
              @apply lg:w-[calc(50%_-_1rem)];
            }
            .tabs-img-2 {
              @apply lg:w-[50%];
            }
          }
        }

        .team-members {
          @apply grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4;
          .member {
            @apply col-span-1 mb-10;
            .member-pic {
              @apply relative;
              img {
                @apply w-full h-auto object-cover pb-4;
              }
              .badge {
                @apply absolute right-2 bottom-6 w-6 h-6;
              }
            }
            .member-name {
              @apply text-white pb-4;
            }
            .member-title {
              @apply text-white;
            }
          }

        }
      }
    }
  }
}
img{
  @apply pointer-events-none;
}
</style>

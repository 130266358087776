<template>
  <section class="projects-section">
    <div class="projects-inner-section">
      <div class="projects-filter z-3"
        :class="filtersOpen ? 'absolute  w-[100vw] left-0 py-2 md:py-6 p-6' : '!shadow-none'"
        style="transition-duration: 0s; ">

        <slide-up-down class="max-h-[67vh] overflow-hidden" tag="div" v-model="filtersOpen" :duration="1000">
          <div class="projects-filter-container">
            <div class="top-content w-full bg-white">
              <div class="top-content-inner max-w-screen-xl mx-auto flex justify-between pt-5 pb-10">
                <p class="text-primary">Select Categories</p>
                <img @click="closeFilters()" src="@/assets/img/cancel.svg" class="cursor-pointer" />
              </div>
            </div>
            <div class="filter-inner-container">
              <template v-if="isMobile">
                <vue-collapsible-panel-group>
                  <div v-for="(category, index) of categories" :key="index" class="filter-category w-full"
                    :class="category.options.length > 9 ? 'lg:w-[60%] xl:w-[50%]' : 'lg:w-[18%] xl:w-[20%]'">
                    <vue-collapsible-panel :expanded="false">
                      <template #title>
                        <p class="category-title">{{ category.name }}</p>
                      </template>
                      <template #content>
                        <div class="filter-items-container">
                          <div v-for="(filter, i) of category.options" :key="i" class="filter-item">
                            <input type="checkbox"
                              class="focus:ring focus:ring-transparent accent-primary text-primary !bg-none"
                              :id="'filter-' + filter.id" v-model="selectedCategories" :value="filter.id">
                            <label :for="'filter-' + filter.id" v-html="filter.name"></label>
                          </div>
                        </div>
                      </template>
                    </vue-collapsible-panel>
                  </div>
                </vue-collapsible-panel-group>
              </template>
              <template v-else>
                <div v-for="(category, index) of categories" :key="index" class="filter-category ">
                  <div class="inner-category-container">
                    <p class="category-title"  :class="filtersOpen ? 'opacity-100' : 'opacity-0'">{{ category.name }}</p>

                    <div class="filter-items-container">
                      <div v-for="(filter, idx) of category.options" :key='`filter-${idx}`' class="filter-item">
                        <input type="checkbox"
                          class="focus:ring focus:ring-transparent accent-primary text-primary !bg-none"
                          :id="'filter-' + filter.id" v-model="selectedCategories" :value="filter.id">
                        <label :for="'filter-' + filter.id" v-html="filter.name"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </slide-up-down>
        <div class="bottom-content">
          <div class="inner-bottom-content">
            <transition name="fade" mode="out-in">
              <h4 class='text-lg leading-snug lg:text-xl' v-if="!filtersOpen">Explore project categories</h4>
              <h4 v-else></h4>
            </transition>
            <button @click="openFilters()" class="filters-button w-7/12 md:w-max">{{
                filtersOpen ? 'Apply Filters' : 'Filter Projects'
            }}
            </button>
          </div>
        </div>
      </div>
      <div class="projects-gallery mt-8 flex flex-wrap gap-4">
          <div v-show="loading" class="skeleton-rows">
            <div class="skeleton-unit">
              <span class="skeleton-box w-6/12 lg:w-1/5 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-4/12 h-14"></span>
            </div>
            <div class="skeleton-unit">
              <span class="skeleton-box w-6/12 lg:w-1/5 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-4/12 h-14"></span>
            </div>
            <div class="skeleton-unit full">
              <span class="skeleton-box w-6/12 lg:w-1/5 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-4/12 h-14"></span>
            </div>
            <div class="skeleton-unit">
              <span class="skeleton-box w-6/12 lg:w-1/5 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-4/12 h-14"></span>
            </div>
            <div class="skeleton-unit">
              <span class="skeleton-box w-6/12 lg:w-1/5 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-4/12 h-14"></span>
            </div>
            <div class="skeleton-unit full">
              <span class="skeleton-box w-6/12 lg:w-1/5 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-6/12 h-4"></span>
              <span class="skeleton-box w-full lg:w-4/12 h-14"></span>
            </div>
          </div>
          <project-card v-for="(project, index) of currentProjects" :key="index" :idProject="project.id"
          :width="(index + 1) % 3 === 0 ? 'full' : 'half'" :title="project.title.rendered"
          :category="project.primary_category"
          :image="project.acf?.hero_section?.project_hero_image?.sizes.large ? project.acf.hero_section.project_hero_image.sizes.large : project.acf?.hero_section?.project_hero_image?.url ? project.acf?.hero_section?.project_hero_image?.url : defaultImage"
          :video="project.acf.hero_section.video_file" :description="project.acf.content" :link="project.link"
          :isProject="true" :ctaContent="data.projects_module" :shotCTA="true" :download="false" :index="index" />
      </div>
      <div class="bottom-section mt-10">
        <transition name="fade" mode="out-in">
          <button v-if="!noMoreResults" @click="fetchMore()" class="px-8 py-2 bg-accentGreen text-text">More Projects</button>
          <div v-else class="no-more-results">
            <p class="text-center">No more projects to show...</p>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStore } from 'vuex'
import { onBeforeMount, onMounted, ref, computed, onBeforeUnmount, onUpdated, getCurrentInstance } from 'vue'
import SlideUpDown from 'vue3-slide-up-down'
import ProjectCard from './Project-card.vue'
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@dafcoe/vue-collapsible-panel'
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import defaultImage from '@/assets/img/default-cover-news.jpg'

const mainStore = useStore()
const projects = ref(null)
const heightObserver = ref()
const selectedCategories = ref([])
const currentPage = ref(1)
const totalPages = ref(null)
const noMoreResults = ref(false)
const filtersOpen = ref(false)
const isMobile = ref(false)
const hasAnimated = ref(false)
const curr = getCurrentInstance()
const mm = gsap.matchMedia()
const loading = ref(false)

defineProps(['data'])

onBeforeMount(async () => {
  loading.value = true
  await mainStore.dispatch('getItems', {
    type: 'projects',
    params: {
      per_page: 7,
      page: currentPage.value,
      orderby: 'date',
      order: 'desc'
    }
  })
  await mainStore.dispatch('getItems', {
    type: 'projects_categories',
    params: {
      per_page: 100,
      page: currentPage.value
    }
  })
  projects.value = initialProjects().projectList
  totalPages.value = initialProjects().totalPages
  isMobile.value = window.innerWidth < 1024
  addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 1024
  })
  loading.value = false
})

onMounted(() => {
  heightObserver.value = new ResizeObserver(
    () => {
      setTimeout(() => {
        ScrollTrigger.refresh()
        // console.log('updated height')
      }, 200)
    }
  )
  heightObserver.value.observe(document.querySelector('body'))
})

onUpdated(() => {
  if (totalPages.value <= currentPage.value) {
    noMoreResults.value = true
  } else {
    noMoreResults.value = false
  }
  if (!hasAnimated.value) {
    animateComponents()
    hasAnimated.value = true
  }
  ScrollTrigger.update()
})

onBeforeUnmount(() => {
  removeEventListener('resize', () => {
    isMobile.value = window.innerWidth < 1024
  })
  heightObserver.value.disconnect()
})

const animateComponents = () => {
  gsap.registerPlugin(ScrollTrigger)
  // get width of viewport and if it's mobile, set the width to 100%

  ScrollTrigger.create({
    trigger: '.projects-gallery',
    scroller: '[data-scroll-container]',
    start: 'top 100',
    end: 'bottom center',
    pin: '.projects-filter',
    pinSpacing: false
  })

  mm.add('(max-width: 767px)', () => {
    ScrollTrigger.create({
      trigger: '.projects-inner-section',
      scroller: window,
      start: 'top-=40 top',
      endTrigger: '.projects-inner-section',
      end: 'bottom+=55px center',
      pin: '.projects-filter',
      fastScrollEnd: true,
      pinSpacing: false,
      markers: false
    })
  })
}

const openFilters = () => {
  if (curr.root.data.locoScroll.scroll.instance.scroll.y < window.innerHeight + 100) {
    curr.root.data.locoScroll.scrollTo('.projects-section')
  }

  if (!filtersOpen.value) {
    filtersOpen.value = true
    curr.root.data.locoScroll.stop()
  } else {
    filtersOpen.value = false
    filteredResults()
  }
}

const closeFilters = () => {
  filtersOpen.value = false
  curr.root.data.locoScroll.start()
}

const filteredResults = async (fetchMore = false) => {
  loading.value = true
  if (fetchMore) {
    currentPage.value++
  } else {
    currentPage.value = 1
    projects.value = null
  }

  let params = {
    per_page: 7,
    'projects_categories[terms]': selectedCategories.value.toString().replace(/' '/g, ','),
    'projects_categories[operator]': 'AND',
    page: currentPage.value,
    orderby: 'date',
    order: 'desc'
  }
  if (selectedCategories.value.length === 0) {
    params = {
      per_page: 7,
      page: currentPage.value,
      orderby: 'date',
      order: 'desc'
    }
  }
  await mainStore.dispatch('getItems', {
    type: 'projects',
    params
  })
  const newProjects = mainStore.getters.requestedItems({
    type: 'projects',
    params
  })
  const newTotalPages = mainStore.getters.totalPages({
    type: 'projects',
    params
  })

  const filterResults = () => {
    const filteredProjects = []
    for (const project of newProjects) {
      const passed = []
      let totalPassed = 0
      for (const filter of selectedCategories.value) {
        if (project.projects_categories.includes(filter)) {
          passed.push(true)
        } else {
          passed.push(false)
        }
      }

      for (const trues of passed) {
        if (trues) {
          totalPassed++
        }
      }

      if (!passed.includes(false) && totalPassed === selectedCategories.value.length) {
        filteredProjects.push(project)
      }
    }
    return filteredProjects
  }

  totalPages.value = newTotalPages

  if (!fetchMore) {
    projects.value = filterResults()
    if (Object.keys(projects.value).length === 0) {
      noMoreResults.value = true
    }
    closeFilters()
  }
  if (currentPage.value >= totalPages.value) {
    noMoreResults.value = true
  }
  loading.value = false
  return filterResults()
}

const fetchMore = async () => {
  ScrollTrigger.refresh()

  let newProjects
  if (currentPage.value < totalPages.value) {
    newProjects = await filteredResults(true)
  } else {
    noMoreResults.value = true
  }
  projects.value = [...projects.value, ...newProjects]

  if (filtersOpen.value) {
    closeFilters()
  }
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 100)
}

const currentProjects = computed(() => {
  return projects.value
})

const categories = computed(() => {
  const list = mainStore.getters.requestedItems({
    type: 'projects_categories',
    params: {
      per_page: 100,
      page: 1
    }
  })
  const parents = list.filter(item => {
    if (item.parent === 0) {
      item.options = []
      return item
    } else return null
  })

  for (const item of parents) {
    for (const i of list) {
      if (i.parent === item.id) {
        item.options.push(i)
      }
    }
  }

  return parents.reverse()
})

computed(() => {
  return mainStore.getters.requestedItems({
    type: 'projects_categories',
    params: {
      per_page: 100,
      page: 1
    }
  })
})

const initialProjects = () => {
  const projectList = mainStore.getters.requestedItems({
    type: 'projects',
    params: {
      per_page: 7,
      page: 1,
      orderby: 'date',
      order: 'desc'
    }
  })

  const totalPages = mainStore.getters.totalPages({
    type: 'projects',
    params: {
      per_page: 7,
      page: 1,
      orderby: 'date',
      order: 'desc'
    }
  })

  return { totalPages, projectList }
}

</script>

<style lang="scss" scoped>
.projects-section:deep() {
  @apply relative;

  .projects-inner-section {
    @apply w-full max-w-screen-xl mx-auto px-6 xl: px-0 py-10 pt-10;
    .projects-gallery {
      .skeleton-rows {
        @apply w-full flex flex-wrap gap-6;
        .skeleton-unit {
          @apply w-full bg-[#eaf8f2] lg:w-[calc(50%-1.5rem)] p-6 min-h-100 lg:min-h-130 flex items-start justify-end flex-col;
          &.full{
            @apply w-full;
          }
          .skeleton-box {
            display: inline-block;
            min-height: 1rem;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
            background-color: #DDDBDD;
            &::after {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transform: translateX(-100%);
              background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
              );
              animation: shimmer 2s infinite;
              content: '';
            }
          }
        }
      }
    }
    .projects-filter {
      @apply  transition transition-all w-screen duration-500 ease-in-out bg-white duration-150 absolute left-0;

      .projects-filter-container {
        @apply w-full mx-auto px-6 xl:px-0 bg-white;

        .filter-inner-container {
          @apply grid grid-cols-1 lg:grid-cols-3 gap-9 max-w-screen-xl mx-auto overflow-y-scroll;

          .vcpg {
            @apply border-none;
          }
          .filter-category:first-child {
            @apply col-span-2 ;
            .filter-items-container {
              @apply grid grid-cols-2 ;
            }
          }
          .filter-category {
            @apply pb-6 w-full;

            .vcp {
              &.vcp--expandable {
                @apply border-none;
              }
            }

            .vcp__header {
              @apply bg-white border-b-1 border-b-gray-400 px-0;
            }

            .vcp__body-content {
              .filter-items-container {
                @apply flex flex-col flex-wrap h-auto lg: h-[420px] gap-x-15;

                .filter-item {
                  @apply text-[20px] py-4 border-b-[1px] border-solid border-b-gray-400 flex items-center;

                  input {
                    @apply mr-4 border-primary p-2;
                  }
                }
              }
            }

            .inner-category-container {
              @apply w-full;

              .category-title {
                @apply text-[16px] font-semibold text-black leading-[24px] pb-4
                lg:fixed lg:w-full lg:bg-white lg:-mt-[1px] transition-opacity duration-500 ease-in-out;
                ;
              }

              .filter-items-container {

                @apply h-auto lg: h-[420px] gap-x-10 lg:pt-[43px];

                .filter-item {
                  @apply text-[20px] py-3 border-t-[1px] border-solid border-t-gray-400 flex items-center;

                  input {
                    @apply mr-4 border-primary p-2;
                  }
                }
              }
            }
          }
        }
      }

      .bottom-content {
        @apply w-full bg-white;
        .inner-bottom-content {
          @apply max-w-screen-xl mx-auto px-0 flex flex-row items-center justify-end gap-10 px-6 xl:px-0 py-6;
          .filters-button {
            @apply block bg-accentGreen px-2 py-1 md: px-8 md:py-2;
          }
        }
      }
    }

  }
}
        .filter-inner-container {
          @apply grid grid-cols-1 lg:grid-cols-3 gap-9  overflow-y-scroll;

          .vcpg {
            @apply border-none;
          }
          .filter-category:first-child {
            @apply col-span-2 ;
            .filter-items-container {
              @apply grid grid-cols-2 ;
            }
          }
          .filter-category {
            @apply pb-6 w-full;

            .vcp:deep() {
              @apply border-none max-h-[70vh] overflow-hidden lg:max-h-full;
              .vcp__body {
                .vcp__body-content {
                  @apply max-h-[60vh] lg:max-h-full overflow-y-auto;
                }
              }
            }

            .vcp__header {
              @apply bg-white border-b-1 border-b-gray-400 px-0;
            }

            .vcp__body-content {
              .filter-items-container {
                @apply flex flex-col flex-wrap h-auto lg:h-[420px] gap-x-15;

                .filter-item {
                  @apply text-[20px] py-4 border-b-[1px] border-solid border-b-gray-400 flex items-center;

                  input {
                    @apply mr-4 border-primary p-2;
                  }
                }
              }
            }

            .inner-category-container {
              @apply w-full;

              .category-title {
                @apply text-[16px] font-semibold text-black leading-[24px] pb-4
                lg:fixed lg:w-full lg:bg-white lg:-mt-[1px] transition-opacity duration-500 ease-in-out;
                ;
              }

              .filter-items-container {

                @apply h-auto lg: h-[420px] gap-x-10 lg:pt-[43px];

                .filter-item {
                  @apply text-[20px] py-3 border-t-[1px] border-solid border-t-gray-400 flex items-center;

                  input {
                    @apply mr-4 border-primary p-2;
                  }
                }
              }
            }
          }
        }
        @keyframes shimmer {
          100% {
            transform: translateX(100%);
          }
        }
</style>

<template>
  <article class="post">
    <a :href="post.link" :title="post.post_title">
      <post-taxonomies :post="post" />
      <div class="post-date">
        <time>{{ date }}</time>
      </div>
      <p class="title" v-html="post.title.rendered"></p>
      <post-meta :post="post" />
      <div class="read-more">
        <p>Read More</p>
        <div class="arrows">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </a>
  </article>
</template>

<script>
import ResponsiveImage from "@/components/utility/ResponsiveImage.vue";
import PostMeta from "@/components/utility/PostMeta.vue";
import PostTaxonomies from "@/components/utility/PostTaxonomies.vue";
import { format } from "date-fns";

export default {
  name: "PostItem",
  components: {
    ResponsiveImage,
    PostMeta,
    PostTaxonomies,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: format(new Date(this.post.date), "d MMMM yyyy"),
    };
  },
  mounted() {},
};
</script>

<style lang="scss">
.post {
}
</style>

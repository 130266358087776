<template>
  <div class="responsive-image">
    <template v-if="image">
      <template v-if="size">
        <img
          v-if="size === 'thumbnail'"
          v-lazy="image.media_details.sizes.thumbnail.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :width="image.media_details.sizes.thumbnail ? image.media_details.sizes.thumbnail.width : image.media_details.width"
          :height="image.media_details.sizes.thumbnail ? image.media_details.sizes.thumbnail.height : image.media_details.height"
        />

        <img
          v-else-if="image && size === 'medium'"
          v-lazy="image.media_details.sizes.medium.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :width="image.media_details.sizes.medium ? image.media_details.sizes.medium.width : image.media_details.width"
          :height="image.media_details.sizes.medium ? image.media_details.sizes.medium.height : image.media_details.height"
        />
      </template>
      <template v-else>
        <img
          v-lazy="image.source_url"
          :alt="image.alt_text"
          :title="image.title.rendered ? image.title.rendered : image.alt_text"
          :data-srcset="`
          ${image.media_details.sizes.medium_large ? image.media_details.sizes.medium_large.source_url : image.source_url} 450w,
          ${image.media_details.sizes.large ? image.media_details.sizes.large.source_url : image.source_url} 1024w`"
          :width="image.media_details.width"
          :height="image.media_details.height"
        />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveImage',
  props: {
    mediaId: {
      type: Number,
      required: true
    },
    sizes: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      request: {
        type: 'media',
        id: this.mediaId,
        batch: true
      }
    }
  },
  computed: {
    image() {
      return this.$store.getters.singleById(this.request)
    }
  },
  methods: {
    getMedia() {
      if (this.mediaId) {
        this.$store.dispatch('getSingleById', this.request)
      }
    }
  },
  created() {
    this.getMedia()
  }
}
</script>

<style lang="scss">
.responsive-image {

  img {
    opacity: 0;
    filter: blur(5px);
    transition: all 0.3s ease;
  }

  img[lazy=loading] {
    opacity: 0;
    filter: blur(5px);
    transition: all 0.3s ease;
  }
  img[lazy=error] {
  }
  img[lazy=loaded] {
    opacity: 1;
    filter: blur(0px);
    max-width: 100%;
    height: auto;
  }
}
</style>


<template>
  <section
    v-if="authenticated"
    class="employees"
  >
    <div class="inner-container">
      <div class="inner-container__image-section" :style="`background-image: url(${data.background.url})`"></div>
      <div class="inner-container__content-section">
        <div class="inner-container__links">
        <h4 class="mb-6" v-if="currentUserName">Welcome, {{currentUserName}}</h4>
          <div
            v-for="link in data.Protected_links.links"
            :style="`background-color: ${link.background_color}`"
            class="inner-container__links__card"
            :key="link.text"
          >
            <p>{{ link.text }}</p>
            <Button
              :text="link.link.title"
              :url="link.link.url"
              :target="link.link.target"
              color="white"
            ></Button>
          </div>
          <Button
              text="Sign out"
              color="black"
              @click="signOut()"
            ></Button>
        </div>
      </div>
    </div>
  </section>
  <section
    v-else
    class="employees no-auth"
  >
    <div class="inner-container">
      <div class="inner-container__image-section" :style="`background-image: url(${data.background.url})`"></div>
      <div class="inner-container__content-section">
        <div class="inner-container__links">
          <div
            :style="`background-color: black;`"
            class="inner-container__links__card"
          >
            <p>{{data.Protected_links.SSO_button_heading}}</p>
            <Button
              :text="data.Protected_links.SSO_button_text"
              color="white"
              @click="signIn()"
            ></Button>
          </div>
          <div class="min-h-[75px] min-w-1">
            <transition name="fade" mode="out-in">
              <div v-if="error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                <span class="font-medium">Login attempt failed</span>, try again later.
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/utility/Button.vue'
import { LogLevel, PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser'
export default {
  name: 'Employees',
  data () {
    return ({
      authenticated: false,
      error: false,
      msalConfig: {
        auth: {
          clientId: this.data.azure_configuration.SSO_clientId,
          authority: this.data.azure_configuration.SSO_authority,
          redirectUri: this.data.azure_configuration.SSO_redirect_Uri,
          postLogoutRedirectUri: this.data.azure_configuration.SSO_post_Logout_Redirect_Uri
        },
        cache: {
          cacheLocation: 'sessionStorage', // This configures where your cache will be stored
          storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
          loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                return
              }
              switch (level) {
                case LogLevel.Error:
                  console.error(message)
                  this.error = true
                  return
                case LogLevel.Info:
                  console.info(message)
                  return
                case LogLevel.Verbose:
                  console.debug(message)
                  return
                case LogLevel.Warning:
                  console.warn(message)
              }
            }
          }
        }
      },
      loginRequest: {
        scopes: ['User.Read']
      },
      msalInstance: null,
      currentUser: null,
      currentUserName: null
    })
  },
  methods: {
    createMSALInstance () {
      this.msalInstance = new PublicClientApplication(this.msalConfig)
    },
    selectAccount () {
      const currentAccounts = this.msalInstance.getAllAccounts()
      if (currentAccounts.length > 1) {
        // Add choose account code here
        console.warn('Multiple accounts detected.')
      } else if (currentAccounts.length === 1) {
        this.currentUserName = currentAccounts[0].name ? currentAccounts[0].name : null
        this.currentUser = currentAccounts[0].username
        this.authenticated = true
        this.error = false
      }
    },
    handleResponse (response) {
      if (response !== null) {
        this.currentUserName = response.account.name
        this.currentUser = response.account.username
        this.authenticated = true
        this.error = false
      } else {
        this.selectAccount()
      }
    },
    signIn () {
      this.msalInstance.loginPopup(this.loginRequest)
        .then(this.handleResponse)
        .catch(error => {
          console.error(error)
        })
    },
    signOut () {
      const logoutRequest = {
        account: this.msalInstance.getAccountByUsername(this.currentUser),
        postLogoutRedirectUri: this.msalConfig.auth.redirectUri,
        mainWindowRedirectUri: this.msalConfig.auth.redirectUri
      }
      this.msalInstance.logoutPopup(logoutRequest)
    },
    getTokenPopup (request) {
      request.account = this.msalInstance.getAccountByUsername(this.currentUser)
      return this.msalInstance.acquireTokenSilent(request)
        .catch(error => {
          console.warn('silent token acquisition fails. acquiring token using popup')
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return this.msalInstance.acquireTokenPopup(request)
              .then(tokenResponse => {
                console.log(tokenResponse)
                return tokenResponse
              }).catch(error => {
                console.error(error)
              })
          } else {
            console.warn(error)
          }
        })
    }
  },
  created () {
    // console.log(this.data)
    this.createMSALInstance()
  },
  components: {
    Button
  },
  props: ['data']
}
</script>

<style lang="scss" scoped>
.employees{
  @apply bg-cover bg-center bg-no-repeat flex flex-row justify-end w-full relative min-h-[90vh];
  &.no-auth {
    @apply items-center;
  }
  .inner-container {
    @apply w-full max-w-screen-xl mx-auto px-6 xl:px-0 flex justify-end;
  &__image-section {
    @apply w-full lg:w-7/12 bg-cover bg-no-repeat absolute left-0 -top-10 lg:top-0 -z-1 lg:z-1 h-[110%] lg:h-full;
  }
  &__content-section {
    @apply w-full lg:w-4/12;
  }
  &__links{
    @apply w-full bg-transparent lg:bg-white py-5 lg:py-24 px-5  flex flex-row flex-wrap gap-4;
    &__card{
      @apply w-full lg:w-full px-7 py-10 mb-5;
      p{
        @apply text-white text-[48px];
      }
    }
  }
  }
}
</style>

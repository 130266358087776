<template>
  <section :class="`hero-section main-container ${uid}
   ${this.data.content_section.box_position_responsive === 'yes'
    ? 'h100vh'
    : 'h100por'}
  `">
    <div v-lazy:background="data.image_section.image.sizes?.['1536x1536'] ? data.image_section.image.sizes['1536x1536'] : data.image_section.image.url" class="backgroundImage">
      <div :class="`inner-container ${this.data.content_section.box_position === 'Left'
        ? 'justify-start'
        : this.data.content_section.box_position === 'Center'
          ? 'justify-center'
          : 'justify-end'
        }
              ${this.data.content_section.box_position_responsive === 'yes'
          ? 'h100vh'
          : 'h100por'}
              ${height() > 1200 ? '-mt-50' : ''}
              `
      ">
        <video v-if="data.image_section.show_video" autoplay muted loop playsinline class="video-bg" :poster="data.image_section.image.sizes['1536x1536'] ? data.image_section.image.sizes['1536x1536'] : data.image_section.image.url">
          <source :src="data.image_section.video_file.url" type="video/mp4">
        </video>
        <HeroCard
          class="cardHero"
          ata-scroll
          data-scroll-direction="vertical"
          data-scroll-speed="5"
          :isHero="isHero"
          :uid="uid"
          :subtitle="data.image_section.sub_title"
          :title="data.image_section.title"
          :content="data.content_section.content"
          :button="data.content_section.button"
          >

        </HeroCard>

      </div>
    </div>
  </section>
</template>

<script>

import HeroCard from '@/components/utility/HeroCard.vue'

export default {
  data () {
    return {
      uid: null
    }
  },
  components: {

    HeroCard
  },
  props: ['data'],

  created () {
    this.uid = this.uidGenerate()
    // console.log(this.data, 'data')
  },
  methods: {
    height () {
      return window.innerHeight
    },
    isHero () {
      if (this.$root.introShown) {
        return false
      } else {
        if (
          document
            .querySelectorAll('.hero-section')[0]
            .classList.contains(this.uid)
        ) {
          return true
        } else {
          return false
        }
      }
    },

    uidGenerate () {
      return `element-${Math.floor(Math.random() * (564 * 100))}`
    }
  }
}
</script>
<style lang="scss" scoped>
.h100vh {
  @apply h-[80vh] lg:h-[100vh] ;
}

.h100por {
  @apply h-full;
}

.main-container {
  @apply bg-primary overflow-visible lg:overflow-hidden;
  // box-shadow: 0px 1px 0px 1px rgba(0, 64, 35, 1);

  .backgroundImage {
    @apply w-full h-screen bg-cover bg-center flex flex-row max-h-[65vh] md:max-h-[75vh] lg:max-h-[100vh] bg-cover bg-no-repeat bg-top relative;

    .inner-container {
      @apply w-full max-w-screen-xl mx-auto p-4 lg:pb-20 flex items-end;
      .video-bg {
        @apply w-full h-full object-cover object-center absolute top-0 left-0;
      }
      .cardHero{
        @apply inline-block w-full md:w-[50%] lg:w-[35%] z-1;
      }

    }
  }
}
</style>

<template>
  <section v-if="isLoaded" class="verticalTabs" :style="{ backgroundColor: data.background_color }">
    <div class="inner-container">
      <img class="w-full max-w-[460px] h-auto mb-6" v-if="data.top_logo" :src="data.top_logo.url"
        :alt="data.top_logo.alt" />
      <h2 v-if="data.title !== ''" :style="{ color: data.text_color }">{{ data.title }}</h2>
      <p :style="{ color: data.text_color }">{{ data.description }}</p>
      <div class="verticalTabs__container">
        <ul class="tabs">
          <li v-for="(item, index) in data.content" :key="index">
            <a href="#" @click="selectTab(index)" :style="{ color: data.text_color }" :ref="`tab-${index}`"
              :id="`tab-${index}`" class="item" v-html="item.title "></a>
          </li>
        </ul>
        <template v-if="selectedCaption !== ''">
        <div class="image lightbox">
          <p>{{selectedCaption}}</p>
          <div class="overlay" />
          <img :src="selectedImage" :alt="selectedAlt" />
        </div>
        </template>
        <template v-else>
          <div class="image">
            <img :src="selectedImage" :alt="selectedAlt" />
          </div>
        </template>
        <div class="description">
          <div class="description-content" v-html="selectedDescription" :style="{ color: data.text_color }" />
          <template v-if="Object.keys(selectedButton).length > 0">
            <Button
            :text="selectedButton.data.title"
            :url="selectedButton.data.url"
            :target="selectedButton.data.target"
            :color="selectedButton.color ? selectedButton.color : 'white'"
          />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Button from '@/components/utility/Button.vue'
export default {
  name: 'VerticalTabs',
  components: { Button },
  data () {
    return {
      selectedImage: '',
      selectedAlt: '',
      selectedDescription: '',
      selectedCaption: '',
      selectedButton: {},
      arrImages: [],
      isLoaded: false,
      initialLoad: false
    }
  },
  props: ['data'],
  beforeMount () {
    this.$store.commit('SET_LOADING', true)
  },
  mounted () {
    this.data.content.forEach((item) => {
      this.arrImages.push(item.image.sizes.large ? item.image.sizes.large : item.image.url)
    })

    gsap.registerPlugin(ScrollTrigger)

    this.preloadImgaes(this.arrImages, (data) => {
      this.isLoaded = true
      this.$store.commit('SET_LOADING', false)
      setTimeout(() => {
        this.animationClick()
        // gsap.fromTo(
        //   [
        //     '.inner-container h2',
        //     '.inner-container > p'

        //   ],
        //   { y: 100, autoAlpha: 0 },
        //   {
        //     scrollTrigger: {
        //       trigger: '.inner-container',
        //       start: '-20px center',
        //       scroller: '[data-scroll-container]',
        //       end: 'center center'
        //     },
        //     y: 0,
        //     autoAlpha: 1,
        //     stagger: 0.1
        //   }
        // )
        // gsap.fromTo(
        //   ['.tabs li'],
        //   { y: 100, autoAlpha: 0 },
        //   {
        //     scrollTrigger: {
        //       trigger: '.tabs',
        //       start: '-10% center',
        //       scroller: '[data-scroll-container]',
        //       end: 'center center',
        //     markers: true
        //     },
        //     y: 0,
        //     autoAlpha: 1,
        //     stagger: 0.1
        //   }
        // )
      }, 100)
    })
  },
  updated () {
    if (this.isLoaded && !this.initialLoad) {
      this.selectTab(0)
      this.initialLoad = true
    }
  },
  methods: {
    animationClick () {
      gsap.fromTo(
        [

          '.image',
          '.description'
        ],
        { x: 100, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: '.inner-container',
            start: '-20px center',
            scroller: '[data-scroll-container]',
            end: 'center center'
          },
          x: 0,
          autoAlpha: 1,
          stagger: 0.1
        }
      )
    },
    selectTab (e) {
      this.selectedImage = this.data.content[e].image.sizes.large ? this.data.content[e].image.sizes.large : this.data.content[e].image.url
      this.selectedCaption = this.data.content[e].caption
      this.selectedAlt = this.data.content[e].image.alt
      this.selectedDescription = this.data.content[e].description
      this.selectedButton = this.data.content[e].cta.has_cta_button ? { data: this.data.content[e].cta.cta_button, color: this.data.content[e].cta.button_color } : {}

      const tabs = Object.values(this.$refs)
      tabs.forEach(function (tab) {
        tab[0].classList.remove('active')
      })
      this.$refs[`tab-${e}`][0].classList.add('active')

      this.animationClick()
    },
    async preloadImgaes (Images, callbck) {
      this.$store.commit('SET_LOADING', true)
      for (const [x, e] of Images.entries()) {
        if (x < Images.length - 1) {
          const img = new Image()
          img.src = e
          try {
            await img.decode()
          } catch (error) {
            console.log(error)
          }
        } else {
          const img = new Image()
          img.src = e
          try {
            await img.decode()
          } catch (error) {
            console.log(error)
          }
          img.onload = callbck('done')
        }
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.verticalTabs:deep() {
  @apply px-6 py-20;

  .inner-container {
    @apply w-full max-w-screen-xl mx-auto;

    h2 {
      @apply text-5xl mb-10;
    }

    >p {
      @apply text-base lg: text-3xl font-light w-full lg:w-9/12;
    }

    .verticalTabs__container {
      @apply flex flex-col lg: flex-row w-full justify-between mt-0 lg:mt-20;

      .tabs {
        @apply flex flex-row lg: flex-col w-full lg:w-[21%] mr-10 flex-nowrap border-b-2 lg:border-b-0 border-accentGreen py-3 my-3 lg:py-0 lg:my-0 overflow-x-scroll lg:overflow-x-auto;

        li {
          @apply whitespace-nowrap lg:whitespace-normal  border-b-0 lg: border-b-2 border-accentGreen py-6 w-full first:lg:pt-0 mr-10 lg:mr-0;

          .item {
            @apply text-[16px] mr-5 break-normal lg:break-words;
          }
          .active {
            @apply  !text-accentOrange;
          }
        }
      }
      .image {
        @apply flex flex-row items-center justify-center h-auto overflow-hidden mb-5 w-full lg:w-6/12;

        img {
          @apply w-full h-full object-cover;
        }
        &.lightbox {
          @apply relative overflow-hidden;
          p {
            @apply text-white absolute bottom-4 left-6 opacity-0 transition-opacity duration-300 ease-in-out z-3;
          }
          &:hover p {
            @apply opacity-100 transition-opacity duration-300 ease-in-out;
          }
          .overlay {
            @apply opacity-0 absolute w-full h-full top-0 left-0 bg-black transition-opacity duration-300 ease-in-out z-2;
          }
          &:hover .overlay {
            @apply opacity-50 transition-opacity duration-300 ease-in-out z-2;
          }
          img {
            @apply transform scale-100 transition-transform duration-300 ease-in-out z-1;
          }
          &:hover img {
            @apply transform scale-105 transition-transform duration-300 ease-in-out;
          }
        }
      }

      .description {
        @apply w-full lg:w-[22%] my-0 lg:mt-0;
        .description-content {
          @apply prose;
          * {
            @apply mt-0;
          }
          ul {
            @apply mt-0;
            li {
              @apply pl-5;

              &::before {
                background-color: #00643c;
              }
            }
          }

          div {
            @apply text-base lg:text-xl font-light;

            p {
              @apply mb-4 mt-0;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div data-scroll-container id="root-container" ref="rootContainer" @click="handleClicks">

      <transition name="fade" mode="out-in">
      <SiteIntro v-if="shouldShowIntro" />
    </transition>
    <div class="target" />
    <nav-menu class="main-nav" name="main-menu" />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in" @before-enter="transitionElement" @after-leave="completeTransition">
        <component :class="`${!topHero ? '' : isMobile ? 'pt-[80px]' : 'pt-[110px]'}`" :key="$route.path"
          :is="Component" />
      </transition>
    </router-view>
    <app-footer />
    <transition name="fade" @before-enter="transitionElement" @after-leave="completeTransition">
      <site-loading v-if="loading" />
    </transition>

  </div>
</template>

<script>
import NavMenu from './components/template-parts/NavMenu/NavMenu.vue'
import AppFooter from './components/template-parts/Footer.vue'
import SiteLoading from './components/utility/SiteLoading.vue'
import SiteIntro from './components/utility/Intro.vue'
import LocomotiveScroll from 'locomotive-scroll'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
  components: {
    NavMenu,
    AppFooter,
    SiteLoading,
    SiteIntro
  },
  data () {
    return {
      introShown: false,
      screenWidth: screen.width,
      site: this.$store.state.site,
      showMenu: true,
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      scroll: 0,
      locoScroll: null,
      topHero: false,
      observer: null
    }
  },
  computed: {
    shouldShowIntro () {
      return !this.introShown && !localStorage.getItem('introShown')
    },
    loading () {
      return this.$store.state.site.loading
    },
    logo () {
      if (this.site.logo) {
        return this.$store.getters.singleById({
          type: 'media',
          id: this.site.logo
        })
      } else {
        return null
      }
    }
  },
  methods: {
    recalculateHeight () {
      setTimeout(() => {
        ScrollTrigger.refresh()
        this.locoScroll.update()
        // console.log('recalculated height')
      }, 500)
    },
    transitionElement () {
      this.beforeEnter()
      this.showMenu = true
      this.locoScroll.update()
    },
    completeTransition () {
      this.showMenu = false
      this.locoScroll.update()
    },
    deviceAsign () {
      if (this.screenWidth < 768) {
        this.isMobile = true
        this.isTablet = false
        this.isDesktop = false
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        this.isTablet = true
        this.isMobile = false
        this.isDesktop = false
      } else if (this.screenWidth >= 1024) {
        this.isTablet = false
        this.isMobile = false
        this.isDesktop = true
      }
    },
    beforeEnter () {
      this.$emit('scrollBeforeEnter')
    },
    decodeTitle (title) {
      const txt = document.createElement('textarea')
      txt.innerHTML = title
      return txt.value
    },
    convertToRelative (url) {
      const path = url.replace(this.$store.state.site.url, '')
      return path
    },
    isExternal (url) {
      const domain = (url) => {
        return url.replace('http://', '').replace('https://', '').split('/')[0]
      }
      return domain(location.href) !== domain(url)
    },
    hasIntroShown () {
      if (localStorage.getItem('introShown')) {
        this.$store.state.site.introShown = true
        this.introShown = true
        return true
      } else {
        return false
      }
    },
    introComplete () {
      this.introShown = true
      localStorage.setItem('introShown', true)
      this.$store.state.site.introShown = true
    },
    getLinkEl (el) {
      while (el.parentNode) {
        if (el.tagName === 'A') return el
        el = el.parentNode
      }
    },
    handleClicks (e) {
      const a = this.getLinkEl(e.target)
      if (a && a.href.includes(this.site.url)) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
          e
        // don't handle if has class 'no-router'
        if (a.className.includes('no-router')) return
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (a.target && a.target.includes('_blank')) return
        // don't handle same page links
        const currentURL = new URL(a.href, window.location.href)
        if (currentURL && currentURL.pathname === window.location.pathname) {
          // if same page link has same hash prevent default reload
          if (currentURL.hash === window.location.hash) e.preventDefault()
          return
        }
        // Prevent default and push to vue-router
        e.preventDefault()
        const path = a.href.replace(this.site.url, '')
        this.$router.push(path)
      }
    }
  },
  created () {
    this.$store.dispatch('getAcfOptions')
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    this.locoScroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true
    })

    this.locoScroll.on('scroll', ScrollTrigger.update)
    this.locoScroll.on('scroll', (e) => {
      this.scroll = e.scroll.y
    })

    ScrollTrigger.scrollerProxy('[data-scroll-container]', {
      scrollTop: (value) => {
        return arguments.length
          ? this.locoScroll.scrollTo(value, 0, 0)
          : this.locoScroll.scroll.instance.scroll.y
      },
      getBoundingClientRect () {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight
        }
      },
      pinType: document.querySelector('[data-scroll-container]').style.transform
        ? 'transform'
        : 'fixed'
    })

    ScrollTrigger.addEventListener('refresh', () => this.locoScroll.update())
    ScrollTrigger.refresh()

    this.deviceAsign()
    this.hasIntroShown()
    window.addEventListener('resize', (e) => {
      this.deviceAsign()
      this.screenWidth = screen.width
    })

    this.observer = new ResizeObserver(() => this.locoScroll.update()).observe(document.querySelector('[data-scroll-container]'))
  },
  unmounted () {
    window.removeEventListener('resize', (e) => {
      this.deviceAsign()
      this.screenWidth = screen.width
    })
    this.observer.disconnect()
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/reset";
@import "@/assets/styles/global";

/* Vue transition classes
-------------------------------------------- */

#root-container{
  position: relative;
}
.target{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.4s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.4s ease-in;
}
</style>

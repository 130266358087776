import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import VueLazyLoad from 'vue3-lazyload'
import 'virtual:windi.css'
import { plugin, defaultConfig } from '@formkit/vue'
// import VueGtag from 'vue-gtag'
// import smoothscroll from 'smoothscroll-polyfill'
import 'atropos/css'
import VueGoogleMaps from '@fawmi/vue-google-maps'

// kick off the polyfill!
// smoothscroll.polyfill()

export const Application = createApp(App)

// Application.use(VueGtag, {
//   config: { id: 'UA-41682333-1' }
// })

Application.use(router)
  .use(VueLazyLoad, {
    adapter: {
      loaded ({
        bindType,
        el,
        naturalHeight,
        naturalWidth,
        $parent,
        src,
        loading,
        error,
        Init
      }) {
        setTimeout(() => el.classList.add('loaded'), 100)
      }
    }
  })
  .use(plugin, defaultConfig)
  .use(store)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyA8QTtUr9ZUb6fk_e0_a3z758KxHAl7jsc'
      // language: 'de',
    }
  })
  .mount('#app')
router.beforeEach((to, from, next) => {
  next()
})

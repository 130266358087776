<template>
  <section class="stories">
    <div class="search">
      <div class="inner-container">
        <h2>{{ data.search_title }}</h2>
        <div class="inputSearch">
          <input
            type="text"
            :placeholder="data.search_placeholder"
            v-on:keyup="onSearchPost()"
            v-model="searchValue"
            @blur="searchValue = ''"
          />
          <img :src="searchIcon" alt="Search Icon" />
        </div>
      </div>
    </div>
    <div class="grid-area">
      <div class="inner-container">
        <div class="grid-area-title">
          <h3>{{ data.title_in_grid }}</h3>
          <ul class="categories" v-if="allCat.length > 0">
            <li
              v-for="(cat, index) in allCat"
              :key="cat.id"
              :id="cat.id"
              :ref="`tab-${index}`"
              v-on:click="getNews(cat.id, index)"
              class="item"
            >
              {{ cat.name }}
            </li>
          </ul>
        </div>
        <div class="post-gallery" v-if="allPost.length > 0">
        <template v-for="(project, index) of allPost" :key="index">
          <template v-if="project.categories.includes(data.awards_category)">
            <ProjectCard
              width="half"
              :title="project.title.rendered"
              :category="project.primary_category"
              :image="project.featured_image.large"
              :description="project.excerpt.rendered.replaceAll(/<[^>]*>/g, '')"
              :index="index"
              :isProject="false"
              :shotCTA="false"
              :isAwards="true"
              :awardSubtitle="project.acf.award_subtitle"
              :awardName="project.acf.award_name"
              :awardImage="project.acf.award_image"
              :link="project.link"
              :download="false"

            />
          </template>
          <template v-else>
            <ProjectCard
              :width="(index + 1) % 3 === 0 ? 'full' : 'half'"
              :title="project.title.rendered"
              :category="project.primary_category"
              :image="project.featured_image.large"
              :description="project.excerpt.rendered.replaceAll(/<[^>]*>/g, '')"
              :link="project.link"
              :index="index"
              :isProject="false"
              :shotCTA="false"
              :download="false"
            />
          </template>
        </template>
        </div>
        <button
          v-on:click="getMorePost()"
          class="btn-loadmore"
          v-if="actualPage < totalPages && allPost.length > 0"
        >
          Load More
        </button>
        <p v-else class="noMoreResults">
          No more posts to show.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import searchIcon from '@/assets/img/searchBlack.svg'
import ProjectCard from '@/components/modules/Project-card.vue'
import { debounce } from 'debounce'

export default {
  name: 'PostStories',
  components: {
    ProjectCard
  },
  data () {
    return {
      searchIcon,
      allPost: [],
      allCat: [],
      allCatID: [],
      actualPage: 1,
      currentCat: 0,
      searchResult: [],
      searchValue: '',
      totalPages: 1,
      initialLoad: false
    }
  },
  props: ['data'],
  mounted () {
    this.changeActive(0)
  },
  updated () {
    if (!this.initialLoad) {
      this.defaultSelection()
      this.initialLoad = true
    }
  },
  created () {
    this.getCats()
    if (this.$router.currentRoute.value.hash === '') {
      this.getNews(this.currentCat, 0)
    }
  },
  methods: {
    getNews (currentCat, ref) {
      this.currentCat = currentCat
      currentCat = currentCat === 0 ? this.allCatID : currentCat
      this.actualPage = 1
      let params = {}
      this.allPost = []

      if (Array.isArray(currentCat)) {
        params = {
          per_page: 6,
          page: 1,
          categories: currentCat,
          categories_exclude: this.data.awards_category
        }
      } else if (currentCat === this.data.awards_category) {
        params = {
          per_page: 6,
          page: 1,
          categories: currentCat
        }
      } else {
        params = {
          per_page: 6,
          page: 1,
          categories: currentCat
        }
      }

      this.$store.dispatch('getItems', {
        type: 'posts',
        params
      })
        .then(() => {
          const getPosts = this.$store.getters.requestedItems({
            type: 'posts',
            params

          })
          const totalP = this.$store.getters.totalPages({
            type: 'posts',
            params
          })

          this.totalPages = totalP
          this.allPost = [...this.allPost, ...getPosts]
        })

      this.changeActive(ref)
    },
    getMorePost () {
      this.actualPage = this.actualPage + 1
      let params = {}
      if (this.currentCat === this.data.awards_category) {
        params = {
          per_page: 6,
          page: this.actualPage,
          categories: this.currentCat
        }
      } else {
        params = {
          per_page: 6,
          page: this.actualPage,
          categories: this.currentCat
        }
      }
      const paramsAll = {
        per_page: 6,
        page: this.actualPage,
        categories_exclude: this.data.awards_category
      }

      this.$store
        .dispatch('getItems', {
          type: 'posts',
          params: this.currentCat === 0 ? paramsAll : params
        })
        .then(() => {
          const getPosts = this.$store.getters.requestedItems({
            type: 'posts',
            params: this.currentCat === 0 ? paramsAll : params
          })

          this.allPost = [...this.allPost, ...getPosts]
        })
    },
    getCats () {
      this.$store
        .dispatch('getItems', {
          type: 'categories',
          params: {
            per_page: 100,
            page: 1,
            hide_empty: true
          }
        })
        .then(() => {
          const getPosts = this.$store.getters.requestedItems({
            type: 'categories',
            params: {
              per_page: 100,
              page: 1,
              hide_empty: true
            }
          })

          for (const post of getPosts) {
            this.allCat.push(post)
            this.allCatID.push(post.id)
          }
        })
      this.allCat.unshift({
        id: 0,
        name: 'All'
      })
    },
    defaultSelection () {
      const hash = this.$router.currentRoute.value.hash
      if (hash !== '') {
        const removedHash = hash.replace('#', '').toUpperCase()
        const found = this.allCat.findIndex((x) => {
          const normalized = x.name.toUpperCase().replaceAll(' ', '-')
          return normalized === removedHash
        })
        if (found !== -1) {
          this.getNews(this.allCat[found].id, found)
        }
      }
    },
    changeActive (e) {
      const tabs = Object.values(this.$refs)

      tabs.forEach(function (tab, index) {
        tab[0].classList.remove('active')
        if (e === index) {
          tab[0].classList.add('active')
        }
      })
    },
    onSearchPost () {
      this.changeActive(0)
      const getSearch = debounce(this.searchPost, 1000)

      return getSearch()
    },
    searchPost () {
      this.$store
        .dispatch('getItems', {
          type: 'posts',
          params: {
            per_page: 6,
            page: 1,

            search: this.searchValue
          }
        })
        .then(() => {
          const getPosts = this.$store.getters.requestedItems({
            type: 'posts',
            params: {
              per_page: 6,
              page: 1,

              search: this.searchValue
            }
          })
          this.allPost = []

          for (const post of getPosts) {
            this.allPost.push(post)
          }
        })
    }

  }
}
</script>

<style lang="scss" scoped>
.stories {
  .search {
    @apply w-full bg-primary pt-12  pb-12;
    h2 {
      @apply text-white pb-14 hidden;
    }
    .inputSearch {
      @apply flex flex-row items-center bg-white w-full h-24 px-5 lg:px-0;
      input {
        @apply w-11/12 h-full p-12 border-0 outline-none text-base focus:border-none focus:ring-0;
      }
      img {
        @apply w-1/12 h-8;
      }
    }
  }
  .grid-area {
    @apply mt-10 pb-5 lg:pb-10;
    .grid-area-title {
      @apply flex flex-col lg:flex-row justify-between py-14;
      .categories {
        @apply flex flex-row justify-start lg:justify-end;
        li {
          @apply pt-6 lg:pt-0pl-0 pr-6 lg:pl-6 lg:pr-0 cursor-pointer text-text hover:text-accentOrange;
        }
        .active {
          @apply text-accentOrange;
        }
      }
    }
    .post-gallery {
      @apply flex flex-wrap gap-4;
    }
    .btn-loadmore {
      @apply bg-text text-white py-4 px-10 my-10 hover:bg-accentOrange;
    }
    .noMoreResults{
      @apply text-center text-3xl py-5 lg:py-10;
    }
  }
}
.inner-container {
  @apply w-full px-4 lg:px-0 max-w-screen-xl mx-auto;
}
</style>
